import ApplicationController from '../application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['activeForm', 'rarityLabel', 'quantityInput']
  
  connect(){
    super.connect()
    
    if(this.hasRarityLabelTarget && this.hasQuantityInputTarget){
      
      const qty = parseInt(this.quantityInputTarget.value);
      this.setRarityLabel(qty);
    }
  }

  setRarity(event){
    const el = event.currentTarget

    const qty = parseInt(el.value);
    this.setRarityLabel(qty);
  }

  setRarityLabel(qty){
    let nft_type = 'common'
    if(qty == 1) {
      nft_type = 'unicorn';
    } else if(qty < 6) {
      nft_type = 'epic';
    } else if(qty < 11) {
      nft_type = 'rare';
    } else if(qty < 16) {
      nft_type = 'special';
    }

    this.rarityLabelTarget.className = '';
    this.rarityLabelTarget.className = 'nft-label nft-'+nft_type
    this.rarityLabelTarget.childNodes[0].innerHTML = nft_type.toUpperCase()
  }

  toggleActive(){
    this.stimulate('Admin::Nfts#toggleActive', this.activeFormTarget)
      .then(() => {
        iziToast.success({
          title: I18n.t("community.settings.nfts.items.toggle_active.success.title"),
          message: I18n.t("community.settings.nfts.items.toggle_active.success.message"),
          class: "notification is-light"
        });
      })
      .catch(() => console.log("ERROR"))
  }

  destroy(event) {
    event.preventDefault();
    event.stopPropagation();

    var controller = this;
    var stimulusTarget = event.currentTarget;

    Bulma.create('alert', {
      type: 'danger',
      title: I18n.t("community.settings.nfts.destroy.confirm.title"),
      body: I18n.t("community.settings.nfts.destroy.confirm.body"),
      confirm: {
        label: I18n.t("community.settings.nfts.destroy.confirm.btn.confirm"),
        onClick: function(){
          controller.stimulate("Admin::Nfts#destroy", stimulusTarget);
        }
      },
      cancel: I18n.t("community.settings.nfts.destroy.confirm.btn.cancel")
    });

    
    // this.hideLoading();
  }

  
}