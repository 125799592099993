import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // console.log("BEFORE", typeof StonlyWidget)
    window.STONLY_WID = "e5e911be-6014-11ec-82f1-064cee365e5a";
    this.src = null
    ! function(s, t, o, n, l, y, w, g) {
        s.StonlyWidget || ((w = s.StonlyWidget = function() {
                w._api ? w._api.apply(w, arguments) : w.queue.push(arguments)
            }).scriptPath = n, w.queue = [], (y = t.createElement(o)).async = !0,
            (g = new XMLHttpRequest).open("GET", n + "version?v=" + Date.now(), !0), g.onreadystatechange = function() {
                4 === g.readyState && (y.src = this.src = n + "stonly-widget.js?v=" + (200 === g.status ? g.responseText : Date.now()),
                    document.getElementsByTagName('body')[0].append(y));
            }, g.send())
    }(window, document, "script", "https://stonly.com/js/widget/v2/");
    // console.log("AFTER", StonlyWidget)

    StonlyWidget('stopURLWatcher');

    // if(typeof this.element.dataset.guideId != undefined)
    //   StonlyWidget('openGuide', { guideId: this.element.dataset.guideId });
  }

  disconnect(){
    document.querySelectorAll('[script="'+this.src+'"]').forEach(function(el){
      el.remove();
    })
    window.StonlyWidget = null;
    
  }
}
