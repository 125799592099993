
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "ranking", "button" ]
  // static values = { initialCountry: String }

  connect() {
    
  }

  toggle(){
    this.rankingTarget.classList.toggle('is-block');
    this.buttonTarget.classList.toggle('is-hidden');
  }
}
