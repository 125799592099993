import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["entries", "pagination"]

  initialize() {
    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  async loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { 
      this.paginationTarget.classList.add("is-hidden")
      return 
    }
    let url = next_page.href

    const response = await get(url, { responseKind: "json" })
    if (response.ok) {
      const data = await response.json
      this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
      this.paginationTarget.innerHTML = data.pagination
    }
  }
}