import ApplicationController from './application_controller'
import consumer from "../channels/consumer"

// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['fieldsContainer']
  
  connect(){
    super.connect()

    
    let controller = this;
    const live_id = controller.element.dataset['liveId'];
    const live_provider = controller.element.dataset['liveProvider'];
    let last_action = true;

    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    consumer.subscriptions.create("LivePresenceChannel", {
      // Called once when the subscription is created.
      initialized() {
        // console.log("initialized")
        this.update = this.update.bind(this)
        // this.update()
      },
    
      // Called when the subscription is ready for use on the server.
      connected() {
        // console.log("connected")
        this.install()
        this.update()
      },
    
      // Called when the WebSocket connection is closed.
      disconnected() {
        // console.log("disconnected")
        this.uninstall()
      },
    
      // Called when the subscription is rejected by the server.
      rejected() {
        // console.log("rejected")
        this.uninstall()
      },
    
      update(e = null) {
        // console.log(document.visibilityState, document.visibilityState === "visible", document.hasFocus())
        document.visibilityState === "visible" ? this.appear() : this.away()
      },
    
      appear() {
        // Calls `AppearanceChannel#appear(data)` on the server.
        if(last_action !== "appear"){
          last_action = "appear"
          this.perform("create", { live_id: live_id, status: 'active', live_provider: live_provider })
        }
        
      },
    
      away() {
        // Calls `AppearanceChannel#away` on the server.
        if(last_action !== "away"){
          last_action = "away"
          // this.perform("create", { live_id: live_id, status: 'inactive' })
        }
        
      },
    
      install() {
        window.addEventListener("focus", this.update)
        window.addEventListener("blur", this.update)
        this.perform("create", { live_id: live_id, status: 'active', live_provider: live_provider })
        document.addEventListener("turbo:load", this.update)
        document.addEventListener(visibilityChange, this.update)
        this.interval = setInterval(this.update, 600000);
      },
    
      uninstall() {
        window.removeEventListener("focus", this.update)
        window.removeEventListener("blur", this.update)
        document.removeEventListener("turbo:load", this.update)
        document.removeEventListener(visibilityChange, this.update)
        this.perform("create", { live_id: live_id, status: 'active', live_provider: live_provider })
        clearInterval(this.interval);
      },
    
      get documentIsActive() {
        return document.visibilityState === "visible" && document.hasFocus()
      },
  
    })
  }


}