import ApplicationController from '../application_controller'
// import Chart from 'chart.js';
import Chart from 'chart.js/auto';
// const Chart = require('chart.js');

export default class extends ApplicationController {
  static targets = [  ]
  // static values = { initialCountry: String }

  connect () {
    const backgroundColor = [
      'rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255, 159, 64, 0.2)'
    ]

    const borderColor = [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)'
    ]
    
    const data = {
      labels: this.element.dataset['labels'].split(','),
      datasets: [
        {
          label: this.element.dataset['title'],
          data: this.element.dataset['data'].split(','),
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1
        }
      ]
    }

    const config = {
      type: 'bar',
      data: data,
      options: {
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true
      },
    };

    const ctx = document.getElementById('myChart').getContext('2d');
    const myChart = new Chart(ctx, config)
      
  }

  disconnect(){
  }
}
