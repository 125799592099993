import ApplicationController from '../application_controller'
import Rails from '@rails/ujs';
import Card from 'card'
import { Turbo } from '@hotwired/turbo-rails';

export default class extends ApplicationController {
  static targets = [ "form", "card", "errors" ]

  connect() {
    // console.log(this.data)
    window.waitingSubmit = false;
    stripe    = Stripe(this.data.get("public-key"))
    const elements = stripe.elements()
    const style    = {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: '#c4f0ff',
          color: '#fff',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
  
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#87BBFD',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE',
        },
      },
    }

    // this.card = elements.create("card", { style: style })
    // this.card.mount(this.cardTarget)

    var elementStyles = {
      base: {
        '-webkit-font-smoothing': 'antialiased',
        'text-size-adjust': '100%',
        'box-sizing': 'inherit',
        'margin': '0',
        fontFamily: 'Montserrat, sans-serif',
        // align-items: center;
        // border: 1px solid transparent;
        // display: inline-flex;
        fontSize: '16px',
        // height: 2.5em;
        // justify-content: flex-start;
        // lineHeight: '1.5',
        padding: '11px',
        // position: relative;
        // vertical-align: top;
        // background-color: white;
        borderColor: '#dbdbdb',
        borderRadius: '4px',
        // color: #363636;
        boxShadow: 'inset 0 0.0625em 0.125em rgba(32, 32, 32, 0.05)',
        // max-width: 100%;
        // width: 100%;
        '::placeholder': {
          color: '#dbdbdb',
        },
      },
      invalid: {
        color: '#E25950',
  
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };
  
    var elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };

    let cardNumber = elements.create('cardNumber', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardNumber.mount('#community_transaction_stripe_number_container');
  
    let cardExpiry = elements.create('cardExpiry', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardExpiry.mount('#community_transaction_stripe_expiry_container');
  
    let cardCvc = elements.create('cardCvc', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardCvc.mount('#community_transaction_stripe_cvc_container');
  
    // registerElements([cardNumber, cardExpiry, cardCvc], 'card-container');
    
    let error = this.errorsTarget;
    [cardNumber, cardExpiry, cardCvc].forEach(function(element) {
      element.on('change', function(event) {
        if (event.error) {
          error.classList.remove('is-hidden');
          error.innerText = event.error.message;
        } else {
          error.classList.add('is-hidden');
        }
      });
    });

    let errorContainer = this.errorsTarget
    // Handle form submission.
    var form = this.formTarget;
    let controller = this
    form.addEventListener('submit', function (event) {
      event.preventDefault();

      if(window.waitingSubmit){ return false; }

      window.waitingSubmit = true;
      
      stripe.createToken(cardNumber).then((result) => {
        if (result.error) {
          window.waitingSubmit = false;
          errorContainer.innerHTML = result.error.message
          errorContainer.classList.remove('is-hidden');
          // Rails.enableElement(this.element.querySelector("[type=submit]"))
        } else {
          errorContainer.classList.add('is-hidden');
          controller.stripeTokenHandler(result.token)
        }
      })

    });

  }

  toJSONString( form ) {
		var obj = {};
		var elements = form.querySelectorAll( "input, select, textarea" );
		for( var i = 0; i < elements.length; ++i ) {
			var element = elements[i];
			var name = element.name;
			var value = element.value;

			if( name ) {
				obj[ name ] = value;
			}
		}

		return JSON.stringify( obj );
	}

  stripeTokenHandler(token) {
    const hiddenInput = document.createElement("input")

    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", "community_transaction[token]")
    hiddenInput.setAttribute("value", token.id)
    this.formTarget.appendChild(hiddenInput)
    
    // var data = Rails.serializeElement(this.element);
    // var search = location.search.substring(1);
    // data = JSON.parse('{"' + decodeURI(data).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    // const data = JSON.stringify(new FormData(this.formTarget));
    // const data = new FormData(this.element);
    // const data = this.toJSONString(this.formTarget)
    const data = {
      community_transaction: {
        token: token.id, 
        referenceable_type: this.formTarget.querySelector('[name="community_transaction[referenceable_type]"]')?.value,
        referenceable_id: this.formTarget.querySelector('[name="community_transaction[referenceable_id]"]')?.value,
        payment_gateway: this.formTarget.querySelector('[name="community_transaction[payment_gateway]"]')?.value,
        charge_id: this.formTarget.querySelector('[name="community_transaction[charge_id]"]')?.value
      },
      tier_slug: this.formTarget.querySelector('[name="tier_slug"]')?.value,
      email: this.formTarget.querySelector('[name="email"]')?.value
    }
    // console.log(data, this.formTarget, this.element)

    // this.formTarget.submit()
    let controller = this.element

    let errorContainer = this.errorsTarget

    var pageLoader = document.querySelector(".pageloader");
    pageLoader.classList.add("is-active");

    fetch(this.formTarget.getAttribute("action"), {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    }).then(function(response) {
      const full_response = response
      response.json().then(function(response) {
        // console.log(response, full_response);
        if (full_response.ok){
          Turbo.visit(response.redirect_url)
        } else {
          Rails.enableElement(controller.querySelector("[type=submit]"))
          pageLoader.classList.remove("is-active");
          window.waitingSubmit = false;

          errorContainer.innerHTML = response.error.message
          errorContainer.classList.remove('is-hidden');

          iziToast.error({
            title: response.error.title,
            message: response.error.message,
            class: "notification is-light"
          });
        }
      });
    });

    // Rails.ajax({
    //   type: "POST", 
    //   url: this.formTarget.getAttribute("action"),
    //   data: data,
    //   headers: {
    //     'Content-type': 'application/json',
    //   },
    //   contentType: "application/json",
    //   success: function(response){
    //     if (response != ""){
    //       const resp = JSON.parse(response)
          
    //       Turbo.visit(resp.redirect_url)
    //     } else {
    //       Rails.enableElement(controller.querySelector("[type=submit]"))
    //       pageLoader.classList.remove("is-active");
    //     }
    //   },
    //   error: function(response){
    //     Rails.enableElement(controller.querySelector("[type=submit]"))
    //     pageLoader.classList.remove("is-active");

    //     if (response != ""){
    //       const resp = JSON.parse(response)

    //       errorContainer.innerHTML = resp.error.message
    //       errorContainer.classList.remove('is-hidden');

    //       iziToast.error({
    //         title: resp.error.title,
    //         message: resp.error.message,
    //         class: "notification is-light"
    //       });
    //     }
        
        
    //   }
    // })

    // Rails.fire(this.formTarget, 'submit');
  }

}