import ApplicationController from './application_controller'
const YTPlayer = require('yt-player')

/* This is the custom StimulusReflex controller for the Supporter Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = ["player"]
  // static values = { id: Integer }

  connect () {
    var opts = {modestBranding: true, annotations: false, host: 'https://www.youtube-nocookie.com', related: false}
    const player = new YTPlayer(this.element.querySelector("#youtube_iframe"), opts)
    player.load(this.element.dataset.youtubeId)
    player.setVolume(100)
    player.play()
    var timeleft = 60
    // console.log(this.time)
    // setTimeout(this.timeUpdate, 1000)
    var controller = this
    var timeleftContainer = controller.element.querySelector("#timeLeft")
    var downloadTimer = setInterval(function(){
      if(timeleft <= 1){
        clearInterval(downloadTimer);
        controller.element.querySelector("#btn_time_container").classList.add("is-hidden")
        controller.element.querySelector("#btn_confirm").classList.remove("is-hidden")
      }
      // document.getElementById("progressBar").value = 10 - timeleft;
      timeleft -= 1;
      timeleftContainer.innerHTML = timeleft
    }, 1000);

    // player.on('playing', () => {
    //   console.log('playing', player.getDuration(), player.getState()) // => 351.521
    // })
    // player.on('timeupdate', (seconds) => {
    //   console.log('timeupdate', seconds, player.getDuration(), player.getState(), player.getCurrentTime()) // => 351.521
    //   this.time
    // })
    
  }

}

