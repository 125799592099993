import CableReady from 'cable_ready'
import consumer from './consumer'

consumer.subscriptions.create('UsersChannel', {
  received (data) {
    if (data.cableReady) CableReady.perform(data.operations)
  }
})

// consumer.subscriptions.create(
//   {
//     channel: 'HelensChannel',
//     id: 30
//   },
//   {
//     received (data) { if (data.cableReady) CableReady.perform(data.operations) }
//   }
// )