import { Controller } from "stimulus"
import Timer from "easytimer.js";

export default class extends Controller {

  connect() {
    var timer = new Timer();
    const elem = this.element;

    if(parseInt(elem.dataset.seconds) < 86400){
      timer.start({countdown: true, startValues: {seconds: elem.dataset.seconds}});

      elem.innerHTML = timer.getTimeValues().toString();

      timer.addEventListener('secondsUpdated', function (e) {
        elem.innerHTML = timer.getTimeValues().toString();
      });

      timer.addEventListener('targetAchieved', function (e) {
        elem.innerHTML = 'REFRESH!';
      });
    }
  }
}