import { Controller } from "stimulus"
import ConfettiGenerator from "confetti-js";

export default class extends Controller {

  connect() {
    var confettiElement = document.getElementById('confetti-canvas');
    if(confettiElement){
      var confettiSettings = { target: confettiElement };
      var confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
    }
  }
}