import ApplicationController from './application_controller'
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['communityName','communityUrl', 'verificationForm', 'inputValidationCode', 'communityCoin', 'communityCoinAcronym']
  
  fillCommunityCoin(){
    if(this.communityCoinAcronymTarget.value.trim().length == 0){
      this.communityCoinAcronymTarget.value = this.communityCoinTarget.value.replace(/[^a-zA-Z ]/g, "").substr(0,3).toUpperCase()
      this.fillCommunityCoinImage()
    }
  }

  fillCommunityUrl(){
    if(this.communityUrlTarget.value.trim().length == 0){
      this.communityUrlTarget.value = slugify(this.communityNameTarget.value, {strict: true}).substr(0,25)
      this.fillMockup()
    }
  }

  fillCommunityCoinImage(){
    document.querySelector(".community-onboard-coins .onboard-coin-image-abbr").innerHTML = this.communityCoinAcronymTarget.value
  }

  fillMockup(){
    document.querySelector(".community-signup-mockup .mockup-url").innerHTML = 'skillcore.io/'+this.communityUrlTarget.value
    document.querySelector(".community-signup-mockup .mockup-name").innerHTML = this.communityNameTarget.value
  }

  validateCode() {
    // window.last_input = '';
    if (typeof window.last_input === 'undefined') 
      window.last_input = '';

    // console.log("value", this.inputValidationCodeTarget.value, this.inputValidationCodeTarget.value.length, last_input)
    if(this.inputValidationCodeTarget.value.length == 6 && window.last_input != this.inputValidationCodeTarget.value){
      window.last_input = this.inputValidationCodeTarget.value
      Rails.fire(this.verificationFormTarget, 'submit');
    }
  }

  onSendCodeSuccess(event) {
    iziToast.success({
      title: I18n.t('site.signup.verification.resend.success.title'),
      message: I18n.t('site.signup.verification.resend.success.description'),
      class: "notification is-light"
    });
  }

  onSendCodeError(event) {
    iziToast.error({
      title: I18n.t('site.signup.verification.resend.fail.title'),
      message: I18n.t('site.signup.verification.resend.fail.description'),
      class: "notification is-light"
    });
  }

  submit() {
    
    // e.preventDefault()
    // this.stimulate('Reflex#submit').then((e) => {
    //   this.element.reset()
    //   // optional: set focus on the freshly cleared input
    //   this.focusTarget.focus()
    // }).catch(e => {
    //   console.log("ERROR", e);
    // });
  }

  // checkCommunityUrl(){
  //   if(this.communityUrlTarget){
  //     this.stimulate('LeadUserReflex#checkCommunityUrl').then((e) => {
  //       // console.log("elem", this.element, e);
  //       // this.element.reset()
  //       // optional: set focus on the freshly cleared input
  //       // this.focusTarget.focus()
  //     }).catch(e => {
  //       console.log("ERROR", e);
  //     });
  //   }
  // }
}