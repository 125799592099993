import ApplicationController from './application_controller'
import ClipboardJS from 'clipboard/dist/clipboard';
// var card = require("card");

export default class extends ApplicationController {
  static targets = [ "container" ]
  // static values = { initialCountry: String }

  connect () {
    super.connect();

    // var copyElement = document.querySelector('.button.is-copy-link');
    // if(copyElement) {
    //   const clipboard = new ClipboardJS('.button.is-copy-link');

    //   clipboard.on('success', function(e) {
    //     // console.info('Action:', e.action);
    //     // console.info('Text:', e.text);
    //     // console.info('Trigger:', e.trigger);
    //     e.clearSelection();
    //     iziToast.info({
    //       title: I18n.t('site.signup.confirmed.share.copied.title'),
    //       message: I18n.t('site.signup.confirmed.share.copied.description'),
    //       class: "notification is-light"
    //     });
    //   });
    // }

    if(this.hasContainerTarget) {
      this.clipboard = new ClipboardJS("#"+this.containerTarget.id+ " .is-copy-link");
      this.clipboard.on('success', function(e) {
        e.clearSelection();
        // e.preventDefault();
        console.log(e)
        iziToast.info({
          title: I18n.t('site.signup.confirmed.share.copied.title'),
          message: I18n.t('site.signup.confirmed.share.copied.description'),
          class: "notification is-light"
        });

        
      });
    }
  }

  disconnect(){
    this.clipboard.destroy();
  }
}
