import ApplicationController from './application_controller'
import autoComplete from '@tarekraafat/autocomplete.js/dist/js/autoComplete.js';

export default class extends ApplicationController {
  static targets = ['fieldsContainer']
  
  connect(){
    super.connect()

    let elem = this.element;
    const autoCompleteJS = new autoComplete({
      // name: "food & drinks",
      data: {
        src: async function () {
          // Loading placeholder text
          elem.setAttribute("placeholder", "Loading...");
          // Fetch External Data Source
          const source = await fetch(
            elem.dataset.path+"?q="+elem.value
          );
          const data = await source.json();
          // Post loading placeholder text
          // document.querySelector(".autoComplete");
            // .setAttribute("placeholder", "Food & Drinks");
          // Returns Fetched data
          return data;
        },
        key: ["slug"],
        results: (list) => {
          console.log('list', list)
          // Filter duplicates
          const filteredResults = Array.from(
            new Set(list.map((value) => value.match))
          ).map((food) => {
            return list.find((value) => value.match === food);
          });
    
          return filteredResults;
        }
      },
      trigger: {
        event: ["input", "focus"]
      },
      // placeHolder: "Search for Food & Drinks!",
      selector: "#"+elem.id,
      searchEngine: "strict",
      highlight: true,
      maxResults: 5,
      resultItem: {
        content: (data, element) => {
          // Modify Results Item Style
          element.style = "display: flex; justify-content: space-between;";
          // Modify Results Item Content
          element.innerHTML = `<span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
            ${data.match}</span>
            <span style="display: flex; align-items: center; font-size: 13px; font-weight: 100; text-transform: uppercase;;">
          ${data.value.name}</span>`;
        }
      },
      // noResults: (dataFeedback, generateList) => {
      //   // Generate autoComplete List
      //   generateList(autoCompleteJS, dataFeedback, dataFeedback.results);
      //   // No Results List Item
      //   const result = document.createElement("li");
      //   result.setAttribute("class", "no_result");
      //   result.setAttribute("tabindex", "1");
      //   result.innerHTML = `<span style="display: flex; align-items: center; font-weight: 100; color: rgba(0,0,0,.2);">Found No Results for "${dataFeedback.query}"</span>`;
      //   document
      //     .querySelector(`#${autoCompleteJS.resultsList.idName}`)
      //     .appendChild(result);
      // },
      onSelection: (feedback) => {
        document.querySelector("#autoComplete").blur();
        // Prepare User's Selected Value
        const selection = feedback.selection.value[feedback.selection.key];
        // Render selected choice to selection div
        // document.querySelector(".selection").innerHTML = selection;
        // Replace Input value with the selected value
        document.querySelector("#autoComplete").value = selection;
        // Console log autoComplete data feedback
      }
    });
  }

  autoComplete(){
    
  }

}