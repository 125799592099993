import { Controller } from "stimulus"

export default class extends Controller {
  static values = []

  connect() {
    const option_all = this.element.querySelector('input[type="checkbox"][value="all"]')
    if( (option_all && option_all.checked) || 
        this.element.querySelectorAll('input[type="checkbox"]:checked').length == 0){
      this.element.querySelectorAll('input[type="checkbox"]').forEach(function(elem){
        elem.checked=true;
      })
    }
  }

  change(target){
    const el = event.currentTarget
    const option_all = this.element.querySelector('input[type="checkbox"][value="all"]')
    // console.log(el.checked, el.value == '')
    if(el.checked && el.value == 'all'){
      this.element.querySelectorAll('input[type="checkbox"]').forEach(function(elem){
        elem.checked=true;
      })
    } else if (el.value != 'all' && option_all) {
      
      this.element.querySelector('input[type="checkbox"][value="all"]').checked=false;
    }

    if( (option_all && option_all.checked) || 
        this.element.querySelectorAll('input[type="checkbox"]:checked').length == 0){

      this.element.querySelectorAll('input[type="checkbox"]').forEach(function(elem){
        elem.checked=true;
      })

    }
    
  }
}