import { Controller } from "stimulus"
import ConfettiGenerator from "confetti-js";
import AOS from 'aos';
import IMask from 'imask';
import ClipboardJS from 'clipboard/dist/clipboard';

export default class extends Controller {
  // static targets = [ "inputCoinName", "typewriter", "submitBtn" ]
  static targets = ["inputCommunityUrl"]

  connect() {
    var confettiElement = document.getElementById('confetti-canvas');
    if(confettiElement){
      var confettiSettings = { target: confettiElement };
      var confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
    }
    AOS.init({disable: 'mobile'});

    
    // document.addEventListener('optimism:form:invalid', () => {
    //   console.log("csdsds");
    // });

    var verifCodeElement = document.getElementById('lead_user_input_verification_code');
    if(verifCodeElement){
      var maskOptions = {
        mask: '000000'
      };
      var mask = IMask(verifCodeElement, maskOptions);
    }

    var copyElement = document.querySelector('.button.is-copy-link');
    if(copyElement) {
      const clipboard = new ClipboardJS('.button.is-copy-link');

      clipboard.on('success', function(e) {
        // console.info('Action:', e.action);
        // console.info('Text:', e.text);
        // console.info('Trigger:', e.trigger);
        e.clearSelection();
        iziToast.info({
          title: I18n.t('site.signup.confirmed.share.copied.title'),
          message: I18n.t('site.signup.confirmed.share.copied.description'),
          class: "notification is-light"
        });
      });
    }
    
    
  }

  
}