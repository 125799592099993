
import { Controller } from "stimulus"
import IMask from 'imask';

export default class extends Controller {
  // static targets = [ "input" ]
  // static values = { initialCountry: String }

  connect() {
    var phoneMask = IMask(this.element, {
      mask: '000.000.000-00'
    });
  }
}
