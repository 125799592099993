import ApplicationController from './application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['inputCredits', 'inputLimit', 'inputCategory', 'activeForm', 'categoryContainer', 'fieldsContainer']
  
  connect(){
    super.connect();
    if (this.hasInputCreditsTarget){
      IMask(this.inputCreditsTarget, {
        mask: Number,
        scale: 0, 
        min: 1,
        // max: 10000,
        thousandsSeparator: ''
      });
    }
    
    if(this.hasInputCategoryTarget){
      this.enableFields(this.inputCategoryTarget)
      this.toggleMultiple(this.element.querySelector("input#community_mission_perform_multiple"))
      this.toggleReview(this.element.querySelector("input#community_mission_perform_review"))
      this.registerFrequencyChange()
      
    } 

  }

  // initialize(){
  //   this.toggleMultiple(this.element.querySelector("input#community_mission_perform_multiple"))
  // }

  changeCategory(event){
    // const enabled_fields = event.target.options[event.target.selectedIndex].dataset
    this.enableFields(event.target)
    this.sentence();
  }

  enableFields(target){
    const enabled_fields = target.options[target.selectedIndex].dataset
    if(enabled_fields){
      var container = this.element.querySelector("#community_mission_"+this.element.dataset.id+"_perform_goal_container")
      if(enabled_fields.goalEnabled == "true"){
        container.classList.remove("is-hidden")
      } else {  
        container.classList.add("is-hidden")
        container.querySelector("input").value = 1
      }
      container = this.element.querySelector("#community_mission_"+this.element.dataset.id+"_perform_multiple_container")
      if(enabled_fields.userEnabled == "true"){
        container.classList.remove("is-hidden")
      } else {  
        container.classList.add("is-hidden")
        container.querySelector("input").value = null
      }
      container = this.element.querySelector("#community_mission_"+this.element.dataset.id+"_perform_review_container")
      
      if(container){
        if(enabled_fields.reviewEnabled == "true"){
          container.classList.remove("is-hidden")
        } else {  
          container.classList.add("is-hidden")
          container.querySelector("input").value = null
        }
      }
      // container = this.element.querySelector("#community_mission_"+this.element.dataset.id+"_perform_times_container")
      // if(enabled_fields.timesEnabled == "true"){
      //   container.classList.remove("is-hidden")
      // } else {  
      //   container.classList.add("is-hidden")
      //   container.querySelector("input").value = null
      // }
    }
    const category = target.value
    document.querySelectorAll(".category-requirements").forEach((cat) => {
      cat.classList.add("is-hidden");
      cat.querySelectorAll("[required]").forEach((input) => {
        input.removeAttribute("required");
        input.setAttribute("disabled", "disabled");
      });
    });
    

    switch(category){
      case 'survey':
        this.categoryRequirements(".survey-requirements")
      break;
      case 'poll':
        this.categoryRequirements(".poll-requirements")
      break;
      case 'rating':
        this.categoryRequirements(".rating-requirements")
      break;
      case 'yes_no':
        this.categoryRequirements(".yes_no-requirements")
      break;
      case 'youtube_like':
        this.categoryRequirements(".youtube-like-requirements")
      break;
      case 'youtube_subscribe':
        this.categoryRequirements(".youtube-subscribe-requirements")
      break;
      case 'twitter_retweet':
        this.categoryRequirements(".twitter-post-requirements")
      break;
      case 'entity_create':
        this.categoryRequirements(".entity-create-requirements")
      break;
      case 'twitter_follow':
        this.categoryRequirements(".twitter-follow-requirements")
      break;
      case 'link_visit':
        this.categoryRequirements(".link-visit-requirements")
      break;
      case 'discord_join':
        this.categoryRequirements(".discord-join-requirements")
      case 'custom':
        // this.categoryRequirements(".custom-requirements")
      break;
      case 'sweepstake':
        this.fieldsetRequirements(".sweepstake-requirements")
      break;
      case 'quiz':
        this.fieldsetRequirements(".quiz-requirements")
      break;
      case 'ranking':
        this.categoryRequirements(".ranking-requirements")
      break;
      case 'watch_video':
        this.categoryRequirements(".watch-video-requirements")
      break;
      case 'referral':
        this.categoryRequirements(".referral-requirements")
      break;
      default:
        document.querySelector('.s-card-requirements').classList.add('is-hidden')
    }
  }

  // fieldsetRequirements(cls){
  //   var requirements_container = document.querySelector(".requirements-container")
  //   var requirements = document.querySelector(cls)

  //   var fragment = document.createDocumentFragment();
  //   fragment.appendChild(requirements);
  //   requirements_container.prepend(fragment);

  //   requirements.classList.remove("is-hidden");

  //   console.log('requirements', requirements.querySelectorAll("textarea, input, select").querySelectorAll(":not([name*='REQUIREMENT_INDEX'])"))

  //   requirements.querySelectorAll("textarea, input, select").querySelectorAll(":not([name*='REQUIREMENT_INDEX'])").forEach((input) => {
  //     input.setAttribute("required", "required");
  //     input.removeAttribute("disabled");
  //   });

  //   const cardRquirements = document.querySelector('.s-card-requirements')
  //   if (cardRquirements) { cardRquirements.classList.remove('is-hidden') }
  // }

  fieldsetRequirements(cls){
    var requirements_container = document.querySelector(".requirements-container")
    var requirements = document.querySelector(cls)

    requirements.classList.remove("is-hidden");
    requirements.querySelectorAll("textarea, input:not([type='checkbox']), select").forEach((input) => {
      if(input.getAttribute("name").includes("REQUIREMENT_")) return;

      if(!input.dataset.optional){
        input.setAttribute("required", "required");
      }
      input.removeAttribute("disabled");
    });

    const cardRquirements = document.querySelector('.s-card-requirements')
    if (cardRquirements) { cardRquirements.classList.remove('is-hidden') }
    
    if(this.element.dataset.id == '' && this.inputCategoryTarget.value != 'quiz'){
      this.element.querySelector("#community_mission__perform_review_container #community_mission_perform_review").setAttribute("checked", "checked")
    }
  }

  categoryRequirements(cls){
    var requirements_container = document.querySelector(".requirements-container")
    var requirements = document.querySelector(cls)
    // console.log('requirements', requirements)
    var fragment = document.createDocumentFragment();
    fragment.appendChild(requirements);
    requirements_container.prepend(fragment);

    requirements.classList.remove("is-hidden");
    requirements.querySelectorAll("textarea, input, select").forEach((input) => {
      // if(input.getAttribute("name").includes("REQUIREMENT_INDEX")) return;

      if(!input.dataset.optional){
        input.setAttribute("required", "required");
      }
      input.removeAttribute("disabled");
    });

    const cardRquirements = document.querySelector('.s-card-requirements')
    if (cardRquirements) { cardRquirements.classList.remove('is-hidden') }
  }

  pickCategory(event){
    event.preventDefault();
    event.stopPropagation();

    const elem = this.element.querySelector("#community_mission_category")
    elem.value = event.currentTarget.dataset.category;
    
    this.categoryContainerTarget.classList.add("is-hidden")
    this.fieldsContainerTarget.classList.remove("is-hidden")
    this.element.querySelectorAll("button[type=submit]").forEach((b) => {
      b.removeAttribute("disabled")
    });

    this.sentence();

    this.enableFields(elem)
  }

  sentence(){
    // this.stimulate('Admin::Missions#sentence', this.element)
    //   .then(() => {
        
    //   })
    //   .catch(() => console.log("ERROR"))
  }

  toggleActive(){
    this.stimulate('Admin::Missions#toggleActive', this.activeFormTarget)
      .then(() => {
        iziToast.success({
          title: I18n.t("community.settings.missions.items.toggle_active.success.title"),
          message: I18n.t("community.settings.missions.items.toggle_active.success.message"),
          class: "notification is-light"
        });
      })
      .catch(() => console.log("ERROR"))
  }

  destroy(event) {
    event.preventDefault();
    event.stopPropagation();

    var controller = this;
    var stimulusTarget = event.currentTarget;

    Bulma.create('alert', {
      type: 'danger',
      title: I18n.t("community.settings.missions.destroy.confirm.title"),
      body: I18n.t("community.settings.missions.destroy.confirm.body"),
      confirm: {
        label: I18n.t("community.settings.missions.destroy.confirm.btn.confirm"),
        onClick: function(){
          controller.stimulate("Admin::Missions#destroy", stimulusTarget);
        }
      },
      cancel: I18n.t("community.settings.missions.destroy.confirm.btn.cancel")
    });
    // this.hideLoading();
  }

  toggleMultiple(target){
    if(target.checked){
      const freqInput = this.element.querySelector("#community_mission_restriction_attributes_frequency")
      freqInput.value = null
      freqInput.closest(".field").classList.add("is-hidden")

      const userInput = this.element.querySelector("#community_mission_perform_by_user")
      userInput.closest(".field-container").classList.remove("is-hidden")
    } else {
      const freqInput = this.element.querySelector("#community_mission_restriction_attributes_frequency")
      freqInput.closest(".field").classList.remove("is-hidden")

      const userInput = this.element.querySelector("#community_mission_perform_by_user")
      userInput.value = null
      userInput.closest(".field-container").classList.add("is-hidden")

      // console.log("userInput", userInput)
    }
  }

  frequencyChange(target){
    if(!target){ return false; }

    if(target.value == ''){
      this.element.querySelector(".field-time-from").classList.add("is-hidden")
      this.element.querySelector(".field-time-to").classList.add("is-hidden")
    } else {
      this.element.querySelector(".field-time-from").classList.remove("is-hidden")
      this.element.querySelector(".field-time-to").classList.remove("is-hidden")
    }
  }

  onFrequencyChange(target){
    this.frequencyChange(event.currentTarget)
  }

  registerFrequencyChange(){
    const freqInput = this.element.querySelector("#community_mission_restriction_attributes_frequency")
    if(!freqInput){ return false; }

    this.frequencyChange(freqInput)
    freqInput.addEventListener("change", this.onFrequencyChange.bind(this) )
  }

  onMultipleChange(event){
    this.toggleMultiple(event.currentTarget)
  }

  toggleReview(target){
    if(!target){ return false; }
    
    if(target.checked){

      const lnkInput = this.element.querySelector("#community_mission_review_link")
      lnkInput.closest(".field").classList.remove("is-hidden")

      const revInput = this.element.querySelector("#community_mission_review_file")
      revInput.closest(".field").classList.remove("is-hidden")


    } else {
      const lnkInput = this.element.querySelector("#community_mission_review_link")
      lnkInput.value = null
      lnkInput.closest(".field").classList.add("is-hidden")

      const revInput = this.element.querySelector("#community_mission_review_file")
      revInput.value = null
      revInput.closest(".field").classList.add("is-hidden")
    }
  }

  onReviewChange(event){
    this.toggleReview(event.currentTarget)
  }


  

  
}