import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["pricings", "pricingTemplate"];

  addPricing(event) {
    event.preventDefault();
    // console.log(this.fieldsetTemplateTarget.innetHTML)
    const newIndex = this.pricingsTarget.children.length;

    const newFieldset = this.pricingTemplateTarget.cloneNode(true);
    newFieldset.classList.remove("is-hidden");
    newFieldset.removeAttribute("data-admin--items--pricing--form--component-target");
    newFieldset.setAttribute("required", "required");
    newFieldset.innerHTML = newFieldset.innerHTML.replace(/REQUIREMENT_INDEX/g, newIndex);

    this.pricingsTarget.appendChild(newFieldset);
  }

  removePricing(event) {
    event.preventDefault();
    const wrapper = event.target.closest(".components-pricing").parentNode;
    if(wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      console.log(wrapper.querySelector(".pricing-hidden input[name*='_destroy']"))
      wrapper.querySelector(".fieldset-hidden input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }

  }

}