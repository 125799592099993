import { Controller } from "stimulus";
import { put, post } from '@rails/request.js'

export default class extends Controller {
  static targets = [ "pwaAlert", "acceptPwa" ]

  connect() {
    console.log("PWA")
    // let deferredPrompt;
    
    if(!this.element.dataset.redirect){
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevents the default mini-infobar or install dialog from appearing on mobile
        e.preventDefault();
        // Save the event because you'll need to trigger it later.
        window.deferredPrompt = e;

        var pwaAlert = this.element;
        var acceptCookies = this.acceptCookiesTarget;

        pwaAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)
        
        // Show the alert if we cant find the "acceptCookies" cookie
        if (!getCookie("acceptPWA")) {
          // console.log(document.querySelector(".notification.cookiealert.show"))
          if(!document.querySelector(".notification.cookiealert.show"))
            pwaAlert.classList.add("show");
        } else {
          pwaAlert.classList.add("is-hidden");
        }
      })
    }
  }

  async install(){
    console.log('👍', 'butInstall-clicked');
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    console.log('👍', 'userChoice', result);
    if(result['outcome'] == 'accepted'){
      this.requestPushPermission()
    }
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    // divInstall.classList.toggle('hidden', true);
  }

  async requestPushPermission(){

    if( !firebase.messaging.isSupported() || 
        !("Notification" in window) || 
        Notification.permission == 'denied') { 
      return false 
    }

    if(Notification.permission == 'granted') { 
      return true 
    }

    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      const response = await put(this.element.dataset.url, { body: JSON.stringify({ token: token }) })
      
      return token;
    } catch (error) {
      console.error(error);
      return false
    }
  }

  cancel(){
    var pwaAlert = this.element;
    setCookie("acceptPWA", false, 30);
    pwaAlert.classList.remove("show");
  }

  accept(){
    var pwaAlert = this.element;
    setCookie("acceptPWA", true, 30);
    pwaAlert.classList.remove("show");
    this.install()
  }

  openMission(){
    this.installMission();
  }

  async installMission(){
    console.log('install mission', window.deferredPrompt)

    const promptEvent = window.deferredPrompt;

    // const relatedApps = await navigator.getInstalledRelatedApps();

    // console.log('relatedApps', relatedApps)

    // relatedApps.forEach((app) => {
    //   console.log(app.id, app.platform, app.url);
    // });

    if (!promptEvent) {
      // The deferred prompt isn't available.
      this.failMission()
      return false;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    console.log('👍', 'userChoice', result);
    
    if(result['outcome'] == 'accepted'){
      // const permited = null;
      if(this.requestPushPermission()){
        // console.log('requestPushPermission', permited)
        const response = await post(this.element.dataset.redirect, {
          // contentType: "application/javascript",
          responseKind: "turbo-stream",
        })
      } else {
        this.failMission()
      }
    }
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    // divInstall.classList.toggle('hidden', true);
  }

  failMission(){
    iziToast.error({
      title: I18n.t("community.site.missions.modal.pwa.denied.title"),
      message: I18n.t("community.site.missions.modal.pwa.denied.description"),
      class: "notification is-light"
    });
  }
  

}