
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]
  // static values = { initialCountry: String }

  connect() {
    this.element.classList.remove("fadeout")
    this.element.classList.add("fadein")
  }

  close(){
    const el = this.element

    el.classList.remove("fadein")
    el.classList.add("fadeout")
    
    setTimeout(function(){ el.remove() }, 500) 
  }
}
