import ApplicationController from './application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['inputCredits', 'inputLimit', 'activeForm']
  
  connect(){
    super.connect();
    if (this.hasInputCreditsTarget){
      IMask(this.inputCreditsTarget, {
        mask: Number,
        scale: 0, 
        min: 0,
        // max: 10000,
        thousandsSeparator: ''
      });
    }

    if(this.hasInputAmountTarget){
      IMask(this.inputLimitTarget, {
        mask: Number,
        scale: 0,         
        // max: 10000,
        thousandsSeparator: ''
      });
    }
  }

  toggleActive(){
    // console.log(I18n.t("community.settings.rewards"))
    this.stimulate('Reward#toggleActive', this.activeFormTarget)
      .then(() => {
        iziToast.success({
          title: I18n.t("community.settings.rewards.items.toggle_active.success.title"),
          message: I18n.t("community.settings.rewards.items.toggle_active.success.message"),
          class: "notification is-light"
        });
      })
      .catch(() => console.log("ERROR"))
  }

  destroy(event) {
    event.preventDefault();
    event.stopPropagation();

    var controller = this;
    var stimulusTarget = event.currentTarget;

    Bulma.create('alert', {
      type: 'danger',
      title: I18n.t("community.settings.rewards.destroy.confirm.title"),
      body: I18n.t("community.settings.rewards.destroy.confirm.body"),
      confirm: {
        label: I18n.t("community.settings.rewards.destroy.confirm.btn.confirm"),
        onClick: function(){
          controller.stimulate("Reward#destroy", stimulusTarget);
        }
      },
      cancel: I18n.t("community.settings.rewards.destroy.confirm.btn.cancel")
    });

    
    // this.hideLoading();
  }

  
}