import ApplicationController from '../application_controller'
import Rails from "@rails/ujs";
// import { post } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = [ "form", "card", "errors", "full_name", "cvc", "expiry" ]
  

  connect() {
    window.waitingSubmit = false;
    if (this.hasCardTarget){
      var cardnumber_mask = new IMask(this.cardTarget, {
        mask: [
          {
            mask: '0000 000000 00000',
            regex: '^3[47]\\d{0,13}',
            cardtype: 'american express'
          },
          {
            mask: '0000 0000 0000 0000',
            regex: '^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}',
            cardtype: 'discover'
          },
          {
            mask: '0000 000000 0000',
            regex: '^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}',
            cardtype: 'diners'
          },
          {
            mask: '0000 0000 0000 0000',
            regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
            cardtype: 'mastercard'
          },
          // {
          //     mask: '0000-0000-0000-0000',
          //     regex: '^(5019|4175|4571)\\d{0,12}',
          //     cardtype: 'dankort'
          // },
          // {
          //     mask: '0000-0000-0000-0000',
          //     regex: '^63[7-9]\\d{0,13}',
          //     cardtype: 'instapayment'
          // },
          {
            mask: '0000 000000 00000',
            regex: '^(?:2131|1800)\\d{0,11}',
            cardtype: 'jcb15'
          },
          {
            mask: '0000 0000 0000 0000',
            regex: '^(?:35\\d{0,2})\\d{0,12}',
            cardtype: 'jcb'
          },
          {
            mask: '0000 0000 0000 0000',
            regex: '^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}',
            cardtype: 'maestro'
          },
          // {
          //     mask: '0000-0000-0000-0000',
          //     regex: '^220[0-4]\\d{0,12}',
          //     cardtype: 'mir'
          // },
          {
            mask: '0000 0000 0000 0000',
            regex: '^4\\d{0,15}',
            cardtype: 'visa'
          },
          {
            mask: '0000 0000 0000 0000',
            regex: '^62\\d{0,14}',
            cardtype: 'unionpay'
          },
          {
            mask: '0000 0000 0000 0000',
            cardtype: 'Unknown'
          }
        ],
        dispatch: function (appended, dynamicMasked) {
          var number = (dynamicMasked.value + appended).replace(/\D/g, '');
  
          for (var i = 0; i < dynamicMasked.compiledMasks.length; i++) {
          let re = new RegExp(dynamicMasked.compiledMasks[i].regex);
          if (number.match(re) != null) {
              return dynamicMasked.compiledMasks[i];
          }
          }
        }
      });
    }


    if (this.hasExpiryTarget){
      var expirationdate_mask = new IMask(this.expiryTarget, {
        mask: '00/00'
      });
    }

    if (this.hasCvcTarget){
      //Mask the security code
      var securitycode_mask = new IMask(this.cvcTarget, {
        mask: '0000',
      });
    }

    this.formTarget.addEventListener('submit', function(event){ event.preventDefault(); return false; });
  }

  submit(event){
    event.preventDefault();
    if(window.waitingSubmit){ return false; }

    window.waitingSubmit = true;

    let form = this.formTarget;
    let controller = this.element;
    let errorContainer = this.errorsTarget
    let submit_btn = form.querySelector("[type=submit]")
    submit_btn.setAttribute('disabled', 'disabled')
    // Rails.disableElement(form.querySelector("[type=submit]"))
    form.querySelectorAll("[data-iugu-error]").forEach(element => {
      element.innerHTML = ''
    });
    form.querySelectorAll("[data-iugu]").forEach(element => {
      element.classList.remove('is-danger')
    });
    var error_target = this.errorsTarget
    error_target.classList.add('is-hidden')
    var tokenResponseHandler = function(data) {
      console.log('data handle', data)
      if (data.errors) {
        window.waitingSubmit = false;
        submit_btn.removeAttribute('disabled')
        // submit_btn.value = submit_btn_value
        // Rails.enableElement(form.querySelector("[type=submit]"))
        error_target.classList.remove('is-hidden')
        for (const err in data.errors) {
          var error_elem = form.querySelector("[data-iugu-error='"+err+"']")
          if(error_elem)
            error_elem.innerHTML = 'Inválido'
          
          var input_elem = form.querySelector("[data-iugu='"+err+"']")
          if(input_elem)
            input_elem.classList.add('is-danger');
        }
      } else {
        form.querySelector("#community_transaction_token").value = data.id;

        var data = Rails.serializeElement(form);
        var pageLoader = document.querySelector(".pageloader");
        pageLoader.classList.add("is-active");

        Rails.ajax({
          type: "POST", 
          url: form.getAttribute("action"),
          data: data,
          contentType: "application/json",
          success: function(response){
            // console.log("SUCCESS", response)
            
            if (response != ""){
              Turbo.visit(response.redirect_url)
            } else {
              Rails.enableElement(controller.querySelector("[type=submit]"))
              pageLoader.classList.remove("is-active");
              window.waitingSubmit = false;
            }
          },
          error: function(res){
            // console.log("ERROR")
            console.log(res)
            const response = JSON.parse(res);
            console.log(response)

            Rails.enableElement(controller.querySelector("[type=submit]"))
            pageLoader.classList.remove("is-active");
            window.waitingSubmit = false;
    
            if (response != ""){
              
              errorContainer.innerHTML = response.error.message
              errorContainer.classList.remove('is-hidden');
              
              iziToast.error({
                title: response.error.title,
                message: response.error.message,
                class: "notification is-light"
              });
            }
            
            
          }
        })

        // form.submit();
      }
      
    }
    
    Iugu.createPaymentToken(this.formTarget, tokenResponseHandler);
    // Rails.enableElement(form.querySelector("[type=submit]"))
    return false;
      
  }

}