import ApplicationController from '../application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  // static targets = ['activeForm', 'rarityLabel', 'quantityInput']
  
  connect(){
    // super.connect()
  }
  

  onGenerate(){
    this.element.querySelector("input#code_generator_quantity").classList.remove('is-danger')
    this.element.querySelector("input#code_generator_digits").classList.remove('is-danger')

    const qty = this.element.querySelector("input#code_generator_quantity").value
    const dgt = this.element.querySelector("input#code_generator_digits").value
    if(qty > 0 && dgt > 0){
      let generateCodes = []
      for(let i = 0; i < qty; i++){
        generateCodes.push(this.getRandom(dgt))
      }
      console.log('generateCodes', generateCodes)
      document.getElementById('community_item_coupons').value = generateCodes.join("\n")
      // this.element.closest('.right-panel-wrapper').querySelector('.close-panel').click();
      
      let event = new CustomEvent('components--side_panel.state', {
        detail: {
          callback: panelController => {
            panelController.close()
          }
        }
      });

      document.dispatchEvent(event);

    } else {
      if(qty <= 0)
        this.element.querySelector("input#code_generator_quantity").classList.add('is-danger')
      
      if(dgt <= 0)
        this.element.querySelector("input#code_generator_digits").classList.add('is-danger')
    }
  }

  getRandom(length) {

    const rand = Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1));

    return this.leftPadWithZeros(rand, length);
    
  }

  leftPadWithZeros(number, length){
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }

    return str;
  }

  
}