import ApplicationController from './application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['benefitsContainer', 'inputCredits', 'inputAmount']
  
  connect(){
    super.connect()
    if (this.hasInputCreditsTarget){
      IMask(this.inputCreditsTarget, {
        mask: Number,
        scale: 0, 
        min: 1,
        // max: 10000,
        thousandsSeparator: ''
      });
    }

    // if(this.hasInputAmountTarget){
    //   IMask(this.inputAmountTarget, {
    //     mask: Number,
    //     scale: 2, 
    //     radix: '.',
    //     min: 5,
    //     // max: 10000,
    //     thousandsSeparator: ''
    //   });
    // }
  }

  currencyFormatPTtoUS(num) {
    return (
      num
        .replace(',', '.') // replace decimal point character with ,
        .toFixed(2) // always two decimal digits
    ) // use . as a separator
  }

  updateCredits(){
    this.inputCreditsTarget.value = parseInt(this.inputAmountTarget.value.replace(',', '.')*10);
  }

  delete(event) {
    event.preventDefault();
    event.stopPropagation();

    var controller = this;
    var stimulusTarget = event.currentTarget;

    Bulma.create('alert', {
      type: 'danger',
      title: I18n.t("community.settings.pricing.destroy.confirm.title"),
      body: I18n.t("community.settings.pricing.destroy.confirm.body"),
      confirm: {
        label: I18n.t("community.settings.pricing.destroy.confirm.btn.confirm"),
        onClick: function(){
          controller.stimulate("PricingTier#delete", stimulusTarget);
        }
      },
      cancel: I18n.t("community.settings.pricing.destroy.confirm.btn.cancel")
    });

    
    // this.hideLoading();
  }

  incrementCredits(){
    if(this.inputCreditsTarget.value.trim() == "") this.inputCreditsTarget.value = 0;
    this.inputCreditsTarget.value = parseInt(this.inputCreditsTarget.value) + 1
  }

  decrementCredits(){
    if(this.inputCreditsTarget.value.trim() == "") this.inputCreditsTarget.value = 0;

    if(this.inputCreditsTarget.value > 1) this.inputCreditsTarget.value = parseInt(this.inputCreditsTarget.value) - 1;
    
  }

  addBenefit(){
    let inputRef = this.benefitsContainerTarget.querySelector(".benefit-item")
    
    if(inputRef.querySelector("input").value.trim() != ""){
      let input = inputRef.cloneNode(true);
      input.querySelector("input").value = "";
      this.benefitsContainerTarget.appendChild(input)
    }
    
  }

  remBenefit(event){
    if(this.benefitsContainerTarget.querySelectorAll(".benefit-item").length > 1)
      event.target.closest(".control").remove()
    
  }

  pickNft(event){
    if(event.currentTarget.value > 0) {
      this.element.querySelector(".alert-nft-limit").classList.remove('is-hidden')
      this.element.querySelector(".community_pricing_limit_container").classList.add('is-hidden')
    } else {
      this.element.querySelector(".alert-nft-limit").classList.add('is-hidden')
      this.element.querySelector(".community_pricing_limit_container").classList.remove('is-hidden')
    }
  }

}