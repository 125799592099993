
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['value']
  
  decrement() {
    this.valueTarget.stepDown()
    // this.valueTarget.onchange();
    
    var event = new Event('change');
    // Dispatch it.
    this.valueTarget.dispatchEvent(event);
  }
    
  increment() {
    this.valueTarget.stepUp()
    // this.valueTarget.onchange();
    var event = new Event('change');
    // Dispatch it.
    this.valueTarget.dispatchEvent(event);
  }

}