
import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "input" ]
  // static values = { initialCountry: String }

  connect() {
    
  }

  toggle(){
    if(this.element.classList.contains('is-active')){
      this.element.classList.remove('is-active')
    } else {
      this.element.classList.add('is-active')
    }
    // this.element.querySelector('.activation-panel-body').style.display = 'block'
  }
}
