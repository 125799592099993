import ApplicationController from '../application_controller'
import Rails from '@rails/ujs';
import Card from 'card'
import { Turbo } from '@hotwired/turbo-rails';

export default class extends ApplicationController {
  static targets = [ "form", "card", "errors" ]
  static values = {environment: String}

  connect() {
    let form = this.formTarget
    let controller = this
    paypal.Buttons({
      env: this.environmentValue,
      createOrder: function() {
        // $('#transa-type').val("paypal");
        // if (this.isPayment()) {
          var data = Rails.serializeElement(form);
          return new Promise((resolve, reject) => {
            Rails.ajax({
              type: "POST", 
              url: form.dataset.paypalCreateUrl, 
              data: data, 
              contentType: "application/json", 
              success: function(data){
                resolve(data.token);
              },
              error: function (error) {
                reject(error)
              },
            });
          });
        // } // else {}
      },
      onApprove: function(data) {
        // if (this.isPayment()) {
          let dataPayment = {
            paymentID: data.paymentID,
            payerID:   data.payerID
          }
          let dataPayment_param = new URLSearchParams(dataPayment).toString()
          var pageLoader = document.querySelector(".pageloader");
          pageLoader.classList.add("is-active");
          return Rails.ajax({
            type: "POST", 
            url: form.dataset.paypalExecuteUrl, 
            data: dataPayment_param, 
            contentType: "application/json", 
            success: function(res){
              
              controller.submitOrderPaypal(data.paymentID)
            }
          });
        // } // else {}
      }
    }).render('#submit-paypal');

  }

  isPayment() {
    return $('[data-charges-and-payments-section] input[name="community_transaction[product_id]"]:checked').length
  }

  submitOrderPaypal(chargeID) {
    let form = this.formTarget
    // Add a hidden input orders[charge_id]
    // form.append($('<input type="hidden" name="community_transaction[charge_id]"/>').val(chargeID));
    const hiddenInput = document.createElement("input")

    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", "community_transaction[token]")
    hiddenInput.setAttribute("value", chargeID)
    form.appendChild(hiddenInput)
    // Set order type
    var pageLoader = document.querySelector(".pageloader");
    // pageLoader.classList.add("is-active");
    var data = Rails.serializeElement(form);
    Rails.ajax({
      type: "POST", 
      url: form.getAttribute("action"),
      data: data,
      contentType: "application/json",
      success: function(response){
        const resp = JSON.parse(response)
        
        Turbo.visit(resp.redirect_url)
      },
      error: function(response){
        const resp = JSON.parse(response)
        Rails.enableElement(controller.querySelector("[type=submit]"))
        pageLoader.classList.remove("is-active");

        errorContainer.innerHTML = resp.error.message
        errorContainer.classList.remove('is-hidden');

        iziToast.error({
          title: resp.error.title,
          message: resp.error.message,
          class: "notification is-light"
        });
        
      }
    })
  }

}