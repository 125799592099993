import ApplicationController from '../application_controller'
import Rails from "@rails/ujs";
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = [ "form" ]
  

  connect() {
    
  }

  async submit(event){
    event.preventDefault();

    // console.log(event)

    const data = Rails.serializeElement(this.element);
    
    Rails.ajax({
      type: "post",
      url: this.element.getAttribute('action'),
      data: data,
      success: function(data) {
        // console.log(data)
      },
    })
  }

}