import ApplicationController from '../application_controller'
import Taggle from 'taggle'
import autoComplete from '@tarekraafat/autocomplete.js/dist/js/autoComplete.js';
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = []
  
  connect(){
    // new Tagify(this.element)
    const controller = this.element
    const tags = this.element.dataset.tags ? this.element.dataset.tags.split(',') : []
    const hidden = this.element.dataset.hidden ? this.element.dataset.hidden : 'tags[]'
    const attr_key = this.element.dataset.key ? this.element.dataset.key : 'tag'
    this.attr_key = attr_key
    // console.log('tags', this.element)
    this.tagg = new Taggle(this.element, {
      additionalTagClasses: 'tag',
      hiddenInputName: hidden,
      placeholder: '',
      tags: tags,
      containerFocusClass: 'is-primary',

      inputFormatter: function(e){
        e.setAttribute("id", controller.id+"-tafigy-input-id")
      },
      tagFormatter: function(e){
        e.querySelector("button.close").classList.add('delete', 'is-small')
      },
    });

    this.autoComplete();
  }

  autoComplete(){
    var elem = this.element;
    var container = this.tagg.getContainer();
    var input = this.tagg.getInput();

    // console.log('selector: ', input)

    const autoCompleteJS = new autoComplete({
      data: {
        src: async function () {
          if(input.value == '') { return [] }
          elem.setAttribute("placeholder", "Loading...");
          const source = await fetch(
            elem.dataset.path+"?q="+input.value
          );
          // console.log('source', source)
          const data = await source.json();
          return data;
        },
        key: [this.attr_key],
        results: (list) => {
          // Filter duplicates
          console.log('results', list)
          const filteredResults = Array.from(
            new Set(list.map((value) => value.match))
          ).map((tag) => {
            return list.find((value) => value.match === tag);
            
          });
    
          return filteredResults;
        }
      },
      trigger: {
        event: ["input"]
      },
      diacritics: true,
      // query: {
      //   manipulate: function (query) {
      //     return query.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      //   },
      // },
      selector: "#"+input.id,
      searchEngine: "strict",
      highlight: true,
      maxResults: 5,
      debounce: 300,
      resultItem: {
        content: (data, element) => {
          element.style = "display: flex; justify-content: space-between;";
          element.innerHTML = `<span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
            ${data.match}</span>`;
        }
      },
      onSelection: (feedback) => {
        const selection = feedback.selection.value[feedback.selection.key];
        this.tagg.add(selection)
      }
    });
  }
}