import ApplicationController from '../application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['activeForm', 'rarityLabel', 'quantityInput']
  
  connect(){
    super.connect()
    
  }

  toggleActive(){
    this.stimulate('Admin::Notifications#toggle_active', this.activeFormTarget)
      .then(() => {
        iziToast.success({
          title: I18n.t("community.settings.notifications.items.toggle_active.success.title"),
          message: I18n.t("community.settings.notifications.items.toggle_active.success.message"),
          class: "notification is-light"
        });
      })
      .catch(() => console.log("ERROR"))
  }
  
}