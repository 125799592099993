import { Controller } from "stimulus"
import { put, post } from '@rails/request.js'

export default class extends Controller {
  static targets = [ "pushAlert", "acceptPush" ]

  connect() {
    // if (!("Notification" in window) || Notification.permission == 'denied' ||) { return false }
    if( !firebase.messaging.isSupported() || 
        !("Notification" in window) || 
        Notification.permission == 'denied' ||
        Notification.permission == 'granted') { 
      return false 
    }

    if(!this.element.dataset.redirect){

      var pushAlert = this.element;
      var acceptCookies = this.acceptCookiesTarget;

      pushAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)
      
      // Show the alert if we cant find the "acceptCookies" cookie
      if (!getCookie("acceptPush")) {
        pushAlert.classList.add("show");
      } else {
        pushAlert.classList.add("is-hidden");
      }
    }
  }

  cancel(){
    var pushAlert = this.element;
    setCookie("acceptPush", false, 30);
    pushAlert.classList.remove("show");
  }

  accept(){
    var pushAlert = this.element;
    setCookie("acceptPush", true, 30);
    pushAlert.classList.remove("show");
    this.requestPushPermission()
  }

  async requestPushPermission(){
    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      const response = await put(this.element.dataset.url, { body: JSON.stringify({ token: token }) })
      
      return token;
    } catch (error) {
      console.error(error);
    }
  }

  openMission(){
    this.installMission();
  }

  async installMission(){

    this.element.classList.add('is-disabled')

    if( !firebase.messaging.isSupported() || 
        !("Notification" in window) || 
        Notification.permission == 'denied') { 
      this.failMission()
    }

    if( Notification.permission == 'granted') { 
      const response = await post(this.element.dataset.redirect, {
        // contentType: "application/javascript",
        responseKind: "turbo-stream",
      })

      return true 
    }


    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      const response = await put(this.element.dataset.url, { body: JSON.stringify({ token: token }) })

      if(token){
        const response = await post(this.element.dataset.redirect, {
          // contentType: "application/javascript",
          responseKind: "turbo-stream",
        })
      } else {
        this.failMission()
        return false
      }
      
      return token;
    } catch (error) {
      console.error(error);
    }
  }

  failMission(){
    iziToast.error({
      title: I18n.t("community.site.missions.modal.push.denied.title"),
      message: I18n.t("community.site.missions.modal.push.denied.description"),
      class: "notification is-light"
    });
    this.element.classList.remove('is-disabled')
  }
  
}