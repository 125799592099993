import ApplicationController from './application_controller'
import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel.min.js';

export default class extends ApplicationController {
  static targets = ['carousel']
  static values = { style: String }
  
  connect(){
    super.connect()

    let elem = this.element;
    var options = {}
    if(this.hasStyleValue && this.styleValue=='tiny') {
      options = {
        initialSlide: 0,
        pagination: false,
        // slidesToScroll: 1,
        // slidesToShow: 5,
        // loop: true,
        // autoplay: true,
        breakpoints: [{ changePoint: 640, slidesToShow: 3, slidesToScroll: 1 }, { changePoint: 800, slidesToShow: 4, slidesToScroll: 1 }, { changePoint: 960, slidesToShow: 5, slidesToScroll: 1 }, { changePoint: 1152, slidesToShow: 7, slidesToScroll: 1 }, { changePoint: 2304, slidesToShow: 7, slidesToScroll: 1 }, { changePoint: 4608, slidesToShow: 7, slidesToScroll: 1 }  ]
      }
    } else if(this.hasStyleValue && this.styleValue=='small') {
      options = {
        initialSlide: 0,
        // slidesToScroll: 1,
        // slidesToShow: 5,
        // loop: true,
        // autoplay: true,
        breakpoints: [{ changePoint: 640, slidesToShow: 2, slidesToScroll: 1 }, { changePoint: 960, slidesToShow: 3, slidesToScroll: 1 }, { changePoint: 1152, slidesToShow: 4, slidesToScroll: 1 }, { changePoint: 2304, slidesToShow: 5, slidesToScroll: 1 }, { changePoint: 4608, slidesToShow: 6, slidesToScroll: 1 }  ]
      }
    } else if(this.hasStyleValue && this.styleValue=='feed') {
      options = {
        initialSlide: 0,
        // slidesToScroll: 1,
        // slidesToShow: 5,
        // loop: true,
        // autoplay: true,
        breakpoints: [{ changePoint: 640, slidesToShow: 2, slidesToScroll: 1 }, { changePoint: 960, slidesToShow: 3, slidesToScroll: 1 }, { changePoint: 1152, slidesToShow: 3, slidesToScroll: 1 }, { changePoint: 2304, slidesToShow: 3, slidesToScroll: 1 }, { changePoint: 4608, slidesToShow: 3, slidesToScroll: 1 }  ]
      }
    } else {
      options = {
        initialSlide: 0,
        // slidesToScroll: 1,
        // slidesToShow: 1,
        // loop: true,
        // autoplay: true,
        breakpoints: [{ changePoint: 640, slidesToShow: 1, slidesToScroll: 1 }, { changePoint: 960, slidesToShow: 2, slidesToScroll: 1 }, { changePoint: 1152, slidesToShow: 3, slidesToScroll: 1 }, { changePoint: 2304, slidesToShow: 4, slidesToScroll: 1 }, { changePoint: 4608, slidesToShow: 6, slidesToScroll: 1 }  ]
      }
    }

    
    // Initialize all div with carousel class
    var carousels = bulmaCarousel.attach('#'+elem.id, options);
  
    // Loop on each carousel initialized
    // for(var i = 0; i < carousels.length; i++) {
      // Add listener to  event
      // carousels[i].on('before:show', state => {
      //   console.log(state);
      // });
    // }
  }

  autoComplete(){
    
  }

}