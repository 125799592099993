
import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "input" ]
  // static values = { initialCountry: String }

  connect() {
    var modal = Bulma.create('modal', {
      title: this.element.querySelector('#missionTitle').innerHTML,
      body: this.element.querySelector('#missionContent').innerHTML,
      buttons: null,
      style: "card",
      onClose: function(e){
        modal.destroy()
      }
    });
    
    modal.open();
    
    if(typeof gtag != 'undefined') { gtag('event', 'community_user_mission'); }
  }

  close(){
  }
}
