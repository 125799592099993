
import { Controller } from "stimulus"
// import { Controller } from "bulma-extensions/calendar"

// import {bulmaCalendar} from 'bulma-extensions';

// require('bulma-extensions/bulma-calendar')

export default class extends Controller {
  static targets = [ "input" ]
  // static values = { initialCountry: String }

  connect() {
    if(this.hasInputTarget){
      // const calendars = bulmaCalendar.attach('[type="date"]');

      // Bulma.create('calendar', {
      //   parent: this.inputTarget,
      //   navButtons: true
      // });
    }
  }
}
