import ApplicationController from '../application_controller'
// const YTPlayer = require('yt-player')
import YTPlayer from 'yt-player';
import Player from '@vimeo/player';

export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = ["player"]
  // static values = { id: Integer }

  connect () {
    
    if(this.element.dataset.source == "youtube"){
      this.renderYoutube()
    } else if(this.element.dataset.source == "vimeo"){
      this.renderVimeo()
    }
  }

  renderVimeo(){
    const options = {
      url: this.element.dataset.url,
      responsive: true,
      loop: false,
      autoplay: true,
      muted: false,
    };

    this.player = new Player('watch_video_iframe', options);
    // this.player = player
    this.player.setVolume(1);
    const controller = this
    // player.on('play', function() {
    //     console.log('played the video!');
    // });
    this.player.on('timeupdate', (time) => {
      if(time['percent'] >= 0.9){
        // console.log('LIBERAAAA')
        controller.unlockNextStep()
      }
      // console.log('timeupdate', seconds, player.getDuration(), player.getState(), player.getCurrentTime()) // => 351.521
    })
  }

  renderYoutube(){
    var opts = {modestBranding: true, annotations: false, host: 'https://www.youtube-nocookie.com', related: false}
    this.player = new YTPlayer(this.element.querySelector("#watch_video_iframe"), opts)
    // this.player = player
    this.player.load(this.element.dataset.id)
    this.player.setVolume(100)
    this.player.play()
    // var timeleft = 60
    // console.log(this.time)
    // setTimeout(this.timeUpdate, 1000)
    const controller = this
    
    // player.on('playing', () => {
    //   console.log('playing', player.getDuration(), player.getState()) // => 351.521
    // })
    this.player.on('timeupdate', (seconds) => {
      if(this.player.getCurrentTime() >= (this.player.getDuration() * 0.9) ){
        // console.log('LIBERAAAA')
        controller.unlockNextStep()
      }
      // console.log('timeupdate', seconds, player.getDuration(), player.getState(), player.getCurrentTime()) // => 351.521
    })
  }

  unlockNextStep(){
    // console.log("unlockNextStep")
    this.element.querySelector("#btn_confirm").classList.remove("is-hidden")
    this.element.querySelector("#btn_confirm").removeAttribute("disabled")
  }

  disconnect() {
    this.player.destroy()
  }

}

