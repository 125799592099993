import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [ "name", "username" ]

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  fillUsername() {
    if(this.hasUsernameTarget && this.usernameTarget.value.length == 0)
      this.usernameTarget.value = this.convertToSlug(this.nameTarget.value).substr(0,10)
  }

  convertToSlug(text) {
    return text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'');
  }
}
