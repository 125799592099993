import ApplicationController from '../application_controller'
// var card = require("card");

export default class extends ApplicationController {
  static targets = [  ]
  // static values = { initialCountry: String }

  connect () {
    // Add a click event on various child elements to close the parent modal
    (document.querySelectorAll('.panel-overlay, .close-panel, .right-panel-head .delete, .right-panel-foot .delete') || []).forEach(($close) => {
      // const $target = $close.closest('.modal');
      $close.addEventListener('click', () => {
        this.close();
      });
    });

    document.addEventListener('components--side_panel.state', event => {
      event.detail.callback(this);
    });
  }

  openModal() {
    this.element.classList.add('is-active');
  }

  close() {
    const modalCard = this.element.querySelector('.right-panel-wrapper.is-active .right-panel')
    if(modalCard){ modalCard.classList.add('animate__bounceOutRight'); }

    const modal = this.element
    setTimeout(function(){
      modal.classList.remove('is-active');
      modal.innerHTML = "";
    }, 1000);
    
  }

  disconnect() {
    const el = this.element
    this.close(el)
  }
}