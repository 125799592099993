import ApplicationController from './application_controller'
import SmoothScroll from 'smooth-scroll';
import Rails from '@rails/ujs';

/* This is the custom StimulusReflex controller for the Supporter Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = ["formContainer", "replyContainer"]

  connect () {
    super.connect()
    // this.element.querySelectorAll(".dropdown")

    // let elements = this.element.querySelectorAll(".dropdown");
        
    // Bulma.each(elements, (element) => {
    //   console.log(element)
    //   if(element.hasAttribute('data-bulma-attached')) {
    //     return;
    //   }
    //   Bulma.plugins.dropdown.handler.parse(element);
    // });
  }

  delete(event){

    var controller = this;
    var stimulusTarget = event.currentTarget;

    Bulma.create('alert', {
      type: 'danger',
      title: I18n.t("community.site.user_comments.destroy.confirm.title"),
      body: I18n.t("community.site.user_comments.destroy.confirm.body"),
      confirm: {
        label: I18n.t("community.site.user_comments.destroy.confirm.btn.confirm"),
        onClick: function(){
          // controller.stimulate("UserComment#delete", stimulusTarget);
        }
      },
      cancel: I18n.t("community.site.user_comments.destroy.confirm.btn.cancel")
    });
  }

  update(event){
    var stimulusTarget = event.currentTarget;
    this.stimulate("UserComment#update", stimulusTarget);
  }

  edit(event = null) {
    var showComment = this.element.querySelector("#container_show_comment_"+event.target.dataset.id)
    if (showComment) showComment.classList.toggle("is-hidden")

    var editComment = this.element.querySelector("#container_edit_comment_"+event.target.dataset.id)
    if (editComment) editComment.classList.toggle("is-hidden")
  }


  beforeCreate(element, reflex, error, reflexId) {
    Rails.disableElement(element.querySelector("[type=submit]"))
  }

  beforeUpdate(element, reflex, error, reflexId) {
    Rails.disableElement(element.querySelector("[type=submit]"))
  }

  afterCreate(element, reflex, error, reflexId) {
    Rails.enableElement(element.querySelector("[type=submit]"))
  }

  afterUpdate(element, reflex, error, reflexId) {
    Rails.enableElement(element.querySelector("[type=submit]"))
  }

  createSuccess(element, reflex, error, reflexId) {
    element.reset()
    this.formContainerTarget.classList.add("is-hidden")

    iziToast.success({
      title: I18n.t("community.site.user_comments.create.success.title"),
      message: I18n.t("community.site.user_comments.create.success.message"),
      class: "notification is-light"
    });

  }

  createError(element, reflex, error, reflexId) {
    Rails.enableElement(this.element.querySelector("[type=submit]"))

    iziToast.error({
      title: I18n.t("community.site.user_comments.create.error.title"),
      message: error,
      class: "notification is-light"
    });
  }

  updateSuccess(element, reflex, error, reflexId) {
    element.reset()

    iziToast.success({
      title: I18n.t("community.site.user_comments.update.success.title"),
      message: I18n.t("community.site.user_comments.update.success.message"),
      class: "notification is-light"
    });
  }

  updateError(element, reflex, error, reflexId) {
    Rails.enableElement(this.element.querySelector("[type=submit]"))
    
    iziToast.error({
      title: I18n.t("community.site.user_comments.update.error.title"),
      message: error,
      class: "notification is-light"
    });
  }

  deleteSuccess(element, reflex, error, reflexId) { 
    iziToast.success({
      title: I18n.t("community.site.user_comments.destroy.success.title"),
      message: I18n.t("community.site.user_comments.destroy.success.message"),
      class: "notification is-light"
    });
  }

  deleteError(element, reflex, error, reflexId) {
    iziToast.error({
      title: I18n.t("community.site.user_comments.destroy.error.title"),
      message: error,
      class: "notification is-light"
    });
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="supporter" to your markup alongside
   * data-reflex="Supporter#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Supporter#dance" data-controller="supporter">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Supporter#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "Supporter#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  // danceSuccess(element, reflex, noop, reflexId) {
  //   element.innerText = '\nDanced like no one was watching! Was someone watching?'
  // }

  // danceError(element, reflex, error, reflexId) {
  //   console.error('danceError', error);
  //   element.innerText = "\nCouldn\'t dance!"
  // }

  // afterDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nWhatever that was, it\'s over now.'
  // }

  // finalizeDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nNow, the cleanup can begin!'
  // }
}
