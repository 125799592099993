
import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "input" ]
  // static values = { initialCountry: String }

  connect() {
  }

  toggle(){
    this.element.requestSubmit()
  }
}
