import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    var ProgressBar = require('progressbar.js')
    const container = this.element

    this.bar = new ProgressBar.Circle(this.element.querySelector(".progress-container"), {
      color: '#000',
      // This has to be the same size as the maximum width to
      // prevent clipping
      strokeWidth: 10,
      trailWidth: 1,
      easing: 'easeInOut',
      duration: 3000,
      text: {
        autoStyleContainer: false
      },
      from: { color: '#FFBD21', width: 1 },
      to: { color: '#78cc88', width: 6 },
      // Set default step function for all animate calls
      step: function(state, circle) {
        circle.path.setAttribute('stroke', state.color);
        circle.path.setAttribute('stroke-width', state.width);
    
        var value = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText('');
        } else if (value === 100) {
          circle.setText('DONE');
          container.childNodes.forEach(function (elem) {
            if(typeof elem.classList !== "undefined") { 
              if(elem.classList.contains('show-when-complete')){
                elem.classList.remove('is-hidden')
                elem.classList.add('is-block')
              } else {
                elem.classList.add('is-hidden')
              }
            }
          });
          if(container.dataset.related){
          }
        } else {
          circle.setText(value);
        }
      }
    });
    // this.bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
    this.bar.text.style.fontSize = '1rem';
    
    this.bar.animate(1.0);  // Number from 0.0 to 1.0
  }

  disconnect(){
  }
}
