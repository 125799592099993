import ApplicationController from './application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['input']
  static values = { radix: String,  scale: Number, min: Number, max: Number, separator: String}
  
  connect(){
    if (this.hasInputTarget){
      var config = {
        mask: Number,
        scale: (this.hasScaleValue ? this.scaleValue : 0), 
        min: (this.hasMinValue ? this.minValue : 0),
        // max: 10000,
        thousandsSeparator: ''
      }
      if(this.hasSeparatorValue)
        config['radix'] = this.separatorValue

      IMask(this.inputTarget, config);
    }

  }
  
}