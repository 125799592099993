import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    
    if(window._cio){
      // console.log("1", window._cio)
      // console.log("2")
      if(window._cio.pageHasLoaded){
        window._cio.page(window.location.href);
      } else {
        window._cio.identify({
          // Required attributes
          id: this.element.dataset.id    // Use either cio_id, id, or email.
        });
      }
      
      
    }
  }

  disconnect(){
    // document.getElementById('cio-tracker').remove()
    // _cio = [];
  }

}
