import ApplicationController from '../application_controller'
import Rails from "@rails/ujs";

export default class extends ApplicationController {
  static targets = [ "form", "card", "errors", "cpf", "zipcode" ]
  

  connect() {
    if (this.hasCpfTarget){
      IMask(this.cpfTarget, {
        mask: '000.000.000-00'
      });
    }
    if (this.hasZipcodeTarget){
      IMask(this.zipcodeTarget, {
        mask: '00000-000'
      });
    }
  }

  submit(event){
    event.preventDefault();

    stripe = Stripe(this.data.get("public-key"));
    let controller = this;
    controller.element.querySelector('#error-message').classList.add('is-hidden');

    Rails.ajax({
      type: "post",
      url: controller.element.querySelector('#community_transaction_stripe_intent_url').value,
      success: function(data) {
        controller.element.querySelector('#community_transaction_stripe_intent').value = data['stripe_intent']
        controller.stripeBoleto(data['stripe_intent'])
      },
    })
  }

  stripeBoleto(stripe_intent){
    let controller = this;
    stripe.confirmBoletoPayment(
      stripe_intent,
      {
        payment_method: {
          boleto: {
            tax_id: this.element.querySelector('#community_transaction_tax_id').value,
          },
          billing_details: {
            name: this.element.querySelector('#community_transaction_name').value,
            email: this.element.querySelector('#community_transaction_email').value,
            address: {
              line1: this.element.querySelector('#community_transaction_address').value,
              city: this.element.querySelector('#community_transaction_city').value,
              state: this.element.querySelector('#community_transaction_state').value,
              postal_code: this.element.querySelector('#community_transaction_postal_code').value,
              country: 'BR',
            },
          },
        },
      }
    ).then(function(result) {
      // This promise resolves when the customer closes the modal
      // console.log(result);
      if (result.error) {
        // Display error to your customer
        var errorMsg = controller.element.querySelector('#error-message');
        errorMsg.innerText = result.error.message;
        errorMsg.classList.remove('is-hidden');
      }
      Rails.enableElement(controller.element.querySelector("[type=submit]"))
    });
  }

  findAddress() {
    if(this.zipcodeTarget.value.length == 9){
      let controller_element = this.element;
      Rails.ajax({
        type: "get",
        url: 'https://viacep.com.br/ws/'+this.zipcodeTarget.value+'/json/',
        success: function(data) {
          if (data['erro'] != true) {
            if (controller_element.querySelector('#community_transaction_address').value.trim() == '') { controller_element.querySelector('#community_transaction_address').value = data["logradouro"] } 
            if (controller_element.querySelector('#community_transaction_city').value.trim() == '') { controller_element.querySelector('#community_transaction_city').value = data["localidade"] } 
            if (controller_element.querySelector('#community_transaction_state').value.trim() == '') { controller_element.querySelector('#community_transaction_state').value = data["uf"] } 
          }
        },
      })
    }
    
  }

}