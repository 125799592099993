import { Controller } from "stimulus"
import IMask from 'imask';
import flatpickr from "flatpickr";

export default class extends Controller {
  // static targets = [ "input" ]
  static values = { pattern: String,  time: Boolean, mode: String, calendar: Boolean}

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
    if(this.hasPatternValue){
      
      const attributes = {dateFormat: this.patternValue, enableTime: this.hasTimeValue, defaultDate : null}
      if(this.hasModeValue)
        attributes['mode'] = this.modeValue
      if(this.hasCalendarValue)
        attributes['noCalendar'] = !this.calendarValue
      // var dateMask = IMask(this.element,{ mask: Date, lazy: false, pattern: this.patternValue });
      const elem = this.element.tagName.toLowerCase() === 'input' ? this.element : this.element.querySelector('input')

      this.flatpickr = flatpickr(elem, attributes);
    }
    // console.log(dateMask)
  }

  clear(){
    console.log('clear')
    this.flatpickr.clear();
  }
}
