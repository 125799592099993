
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]
  // static values = { initialCountry: String }

  connect() {
  }

  close() {
    this.element.classList.add('animate__bounceOutDown');

    const container = this.element
    setTimeout(function(){container.classList.add('is-hidden');}, 1000);
    
  }
}
