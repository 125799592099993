import ApplicationController from './application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  
  connect(){
    super.connect()
  }

  cancelSubscription(event) {
    event.preventDefault();
    event.stopPropagation();

    var controller = this;
    var stimulusTarget = event.currentTarget;

    

    Bulma.create('alert', {
      type: 'danger',
      title: I18n.t("community.site.transactions.subscriptions.cancel.confirm.title"),
      body: I18n.t("community.site.transactions.subscriptions.cancel.confirm.body"),
      confirm: {
        label: I18n.t("community.site.transactions.subscriptions.cancel.confirm.btn.confirm"),
        onClick: function(){
          let pageLoader = document.querySelector('.pageloader')
          pageLoader.classList.add("is-active");
          controller.stimulate("Transaction#cancel_subscription", stimulusTarget);
        }
      },
      cancel: I18n.t("community.site.transactions.subscriptions.cancel.confirm.btn.cancel")
    });

    
    // this.hideLoading();
  }

  cancelSubscriptionError(element, reflex, error, reflexId) {
    iziToast.error({
      title: I18n.t("community.site.transactions.subscriptions.cancel.error.title"),
      message: I18n.t("community.site.transactions.subscriptions.cancel.error.message"),
      class: "notification is-light"
    });
    let pageLoader = document.querySelector('.pageloader')
    pageLoader.classList.remove("is-active");
  }

}