import ApplicationController from '../application_controller'
// var card = require("card");

export default class extends ApplicationController {
  static targets = [  ]
  // static values = { initialCountry: String }

  connect () {
    console.log("INIT SWEEPSTAKE")
    var radioButtons = this.element.querySelectorAll('input[type="radio"]');

    radioButtons.forEach(function (radio) {
      radio.addEventListener('change', function (event) {
        radioButtons.forEach(function (otherRadio) {
          if (otherRadio !== event.target) {
            otherRadio.checked = false;
            otherRadio.removeAttribute('required');
          } else {
            otherRadio.setAttribute('required', 'required');
          }
        });
      });
    });
  }

}