
import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [ "panel", "floatButton" ]
  static values = { token: String }

  connect() {
    if(this.hasTokenValue) { 
      consumer.subscriptions.create({ channel: 'WidgetChannel', token: this.tokenValue}, {
        received (data) {
          const missions_float = document.getElementById('missions-float-action')

          missions_float.classList.add('animate__animated', 'animate__rubberBand')
          missions_float.querySelector('.lnir.lnir-rocket').classList.add('pulsate', 'has-text-danger')
        }
      })
    }
  }

  open(){
    this.panelTarget.classList.add('is-active');
    if(this.hasFloatButtonTarget) { this.floatButtonTarget.classList.add('is-hidden'); }
    this.loadContent(this.panelTarget);

    parent.postMessage("menuopen","*");
    this.removeNotification()
  }

  openModal(){
    parent.postMessage("modalopen","*");
    console.log("postMessage -> modalopen")
  }

  closeModal(){
    parent.postMessage("modalclose","*");
    console.log("postMessage -> modalclose")
  }

  close(){
    this.panelTarget.classList.remove('is-active');
    if(this.hasFloatButtonTarget) { this.floatButtonTarget.classList.remove('is-hidden'); }

    parent.postMessage("menulclose","*");
  }

  removeNotification(){
    const missions_float = document.getElementById('missions-float-action')

    missions_float.classList.remove('animate__animated', 'animate__rubberBand')
    missions_float.querySelector('.lnir.lnir-rocket').classList.remove('pulsate', 'has-text-danger')
  }

  outsideOpen(event){
    event.preventDefault();
    let panel = document.getElementById("mission-panel")
    // if(panel == null){return false}
    
    panel.classList.add('is-active');
    document.getElementById("missions-float-action").classList.add('is-hidden');
    this.loadContent(panel);
    this.removeNotification()
  }

  loadContent(panel){
    fetch(panel.dataset.url, { headers: { 'X-Requested-With': 'XMLHttpRequest' }})
      .then(response => response.text())
      .then(html => {
        panel.querySelector('.right-panel').innerHTML = html

        let element = panel.querySelector(".tabs-wrapper");
        let plugins = Bulma.findCompatiblePlugins(element);
    
        Bulma.each(plugins, (plugin) => {
          plugin.handler.parse(element);
        });
      })
  }
}
