import ApplicationController from '../application_controller'
// import Swiper JS
// var Swiper = require('swiper');
// import Swiper, { Navigation, Pagination } from 'swiper'
import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';
// import 'swiper/css/navigation'
// import 'swiper/css/pagination' 
// import 'swiper/css/scrollbar'

export default class extends ApplicationController {
  static targets = [  ]
  // static values = { initialCountry: String }

  connect () {
    const swiper = new Swiper(this.element, {
      slidesPerView: 'auto',
      // spaceBetween: 30,
      freeMode: true,
      lazy: true,
      // loop: true,
      // autoHeight: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    
    });

    this.swiper = swiper
  }

  disconnect(){
    this.swiper.destroy();
  }
}
