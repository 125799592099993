import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "cookieAlert", "acceptCookies" ]

  connect() {
    var cookieAlert = this.element;
    var acceptCookies = this.acceptCookiesTarget;

    if (!cookieAlert) {
       return;
    }

    cookieAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)

    // Show the alert if we cant find the "acceptCookies" cookie
    if (!getCookie("acceptCookies")) {
        cookieAlert.classList.add("show");
    } else {
      cookieAlert.classList.add("is-hidden");
    }

    // When clicking on the agree button, create a 1 year
    // cookie to remember user's choice and close the banner
    acceptCookies.addEventListener("click", function () {
        setCookie("acceptCookies", true, 365);
        cookieAlert.classList.remove("show");
    });
  }

  
}