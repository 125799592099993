import ApplicationController from '../application_controller'
// var card = require("card");

export default class extends ApplicationController {
  static targets = [  ]
  // static values = { initialCountry: String }

  connect () {
    // Add a click event on various child elements to close the parent modal
    (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .delete') || []).forEach(($close) => {
      const $target = $close.closest('.modal');
      $close.addEventListener('click', () => {
        this.closeModal();
      });
    });
    // console.log("INIT MODAL")
  }

  openModal() {
    this.element.classList.add('is-active');
  }

  closeModal() {
    const modalCard = this.element.querySelector('.modal.is-active .modal-card')
    if(modalCard){ modalCard.classList.add('animate__bounceOutDown'); }

    const modal = this.element
    setTimeout(function(){
      modal.classList.remove('is-active');
      modal.innerHTML = "";
    }, 1000);
    
  }

  disconnect() {
    const el = this.element
    this.closeModal(el)
  }
}