import ApplicationController from '../application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {

  static targets = ['inputCredits']
  
  connect(){
    super.connect();
    if (this.hasInputCreditsTarget){
      IMask(this.inputCreditsTarget, {
        mask: Number,
        scale: 0, 
        min: 0,
        // max: 10000,
        thousandsSeparator: ''
      });
    }

  }

  
}