import consumer from './consumer'
import CableReady from 'cable_ready'

consumer.subscriptions.create('OptimismChannel', {
  received (data) {
    // console.log(data)
    // CableReady::Channels.instance[Optimism.channel_proc[self]].dispatch_event(name: "optimism:form:valid");
    if (data.cableReady)
      CableReady.perform(data.operations, {
        emitMissingElementWarnings: false
      })

      if(data.operations.addCssClass && data.operations.addCssClass.length > 0){
        const warning_icon = '<span class="icon is-small is-right"><span class="material-icons">warning</span></span>';
        // const done_icon = '<span class="icon is-small is-right"><span class="material-icons">done</span></span>';
        
        data.operations.addCssClass.forEach(container => {
          if(container.element != null && !container.selector.includes("_form")){
            if(container.element.querySelector("input, textarea") != null){
              container.element.querySelector("input, textarea").classList.add("is-danger");
              let iconElem = container.element.querySelector(".icon-right");
              if(iconElem)
                iconElem.innerHTML = warning_icon;
            }
            if(container.element.querySelector(".control") != null){
              container.element.querySelector(".control").classList.add("has-validation", "has-error");
            }
          }
        });
      }

      if(data.operations.removeCssClass && data.operations.removeCssClass.length > 0){
        // const warning_icon = '<span class="icon is-small is-right"><span class="material-icons">warning</span></span>';
        // const done_icon = '<span class="icon is-small is-right"><span class="material-icons">done</span></span>';

        data.operations.removeCssClass.forEach(container => {
          if(container.element != null){
            if (container.element.querySelector("input")) container.element.querySelector("input").classList.remove("is-danger");
            if (container.element.querySelector("icon-right")) container.element.querySelector(".icon-right").innerHTML = '';
            if (container.element.querySelector(".control")) container.element.querySelector(".control").classList.remove("has-error");
          }
        });
      }
      
  }
})
