import ApplicationController from './application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['fieldsContainer']
  
  connect(){
    super.connect()
  }

  visit(){
    Turbo.visit(this.element.querySelector("option:checked").dataset.url)
  }

}