import ApplicationController from './application_controller'
import Plyr from 'plyr';

/* This is the custom StimulusReflex controller for the Supporter Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = ["player"]
  // static values = { id: Integer }

  connect () {
    if (this.hasPlayerTarget) {
      const player = new Plyr(this.playerTarget);
      // const player = MediaPlayer(
      //   this.playerTarget,
      //   {
      //       prefix: 'media',
      //       lang: {
      //           play: 'play',
      //           pause: 'pause',
      //           mute: 'mute',
      //           unmute: 'unmute',
      //           volume: 'volume',
      //           currentTime: 'current time',
      //           remainingTime: 'remaining time',
      //           enterFullscreen: 'enter fullscreen',
      //           leaveFullscreen: 'leave fullscreen',
      //           download: 'download'
      //       },
      //       svgs: {
      //           play: '#symbol-play',
      //           pause: '#symbol-pause',
      //           mute: '#symbol-mute',
      //           unmute: '#symbol-unmute',
      //           volume: '#symbol-volume',
      //           currentTime: '#symbol-currentTime',
      //           remainingTime: '#symbol-remainingTime',
      //           enterFullscreen: '#symbol-enterFullscreen',
      //           leaveFullscreen: '#symbol-leaveFullscreen',
      //           download: '#symbol-download'
      //       },
      //       timeDir: 'ltr',
      //       volumeDir: 'ltr'
      //   }
      // );
    }
    
  }

  initPlayer() {
    console.log(MediaPlayer)
    
  }
}

