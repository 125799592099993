import ApplicationController from '../application_controller'
// var card = require("card");

export default class extends ApplicationController {
  static targets = [  ]
  // static values = { initialCountry: String }

  connect () {
    twttr.widgets.createTweet(
      this.element.dataset.tweetId,
      this.element, 
      {
        conversation: 'none',
        // cards: 'hidden',
        // dnt: true,
        nofooter: true,
        noheader: true
      }
    );
      
  }

  disconnect(){
  }
}


