
import { Controller } from "stimulus"
import intlTelInput from 'intl-tel-input';
import IMask from 'imask';

export default class extends Controller {
  static targets = [ "input" ]
  static values = { initialCountry: String }

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
    if(this.inputTarget){
      var telInput = intlTelInput(this.inputTarget, {
        // customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
        //   return "e.g. " + selectedCountryPlaceholder;
        // },
        initialCountry: this.initialCountryValue,
        preferredCountries: ['br', 'us', 'ca', 'uk', 'au', 'pt', 'ar'],
        // separateDialCode: true,
        // utilsScript: "/javascripts/phone/utils.js"
      });

      var phoneMask = IMask(this.inputTarget, {
        mask: '+{'+telInput.getSelectedCountryData().dialCode+'} 0000000000000000000'
      });

      this.inputTarget.addEventListener("countrychange", function() {
        // var extension = telInput.getExtension();
        // console.log(telInput.getSelectedCountryData(), extension)
        phoneMask.mask = '+{'+telInput.getSelectedCountryData().dialCode+'} 0000000000000000000'
        // code = $(this).intlTelInput("getSelectedCountryData").dialCode
        // $(this).mask('+'+code+' 00000000009')
      });

    }
  }
}
