import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fieldsets", "options", "fieldsetTemplate", "optionTemplate"];

  connect() {
    this.registerFieldsetCorrectOptionChange()

    this.element.querySelectorAll('.fieldset-option-new .required-field').forEach((input) => {
      input.required = false
    })
  }

  addFieldset(event) {
    event.preventDefault();
    // console.log(this.fieldsetTemplateTarget.innetHTML)
    const newIndex = this.fieldsetsTarget.children.length;

    const newFieldset = this.fieldsetTemplateTarget.cloneNode(true);
    newFieldset.classList.remove("is-hidden");
    newFieldset.removeAttribute("data-admin--fieldset--component-target");
    newFieldset.setAttribute("required", "required");
    newFieldset.innerHTML = newFieldset.innerHTML.replace(/REQUIREMENT_FIELD_INDEX/g, newIndex);

    this.fieldsetsTarget.appendChild(newFieldset);

    newFieldset.querySelectorAll('.required-field').forEach((input) => {
      input.required = true
    })
    newFieldset.querySelectorAll('input, textarea, select').forEach((input) => {
      input.disabled = false
    })
  }

  removeFieldset(event) {
    event.preventDefault();
    if(this.fieldsetsTarget.children.length == 1){ return false; }
    
    const wrapper = event.target.closest(".components-fieldset").parentNode;
    if(wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      // console.log(wrapper.querySelector(".fieldset-hidden input[name*='_destroy']"))
      wrapper.querySelector(".fieldset-hidden input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }

  }

  addOption(event) {
    event.preventDefault();
    const newIndex = event.target.closest(".components-fieldset").querySelector(".component-admin-fieldset-option-list").children.length;
    // console.log('newIndex', newIndex)
    const newOption = event.target.closest(".fieldset-option-new").querySelector(".fieldset-option-new-object").cloneNode(true);
    newOption.classList.remove("is-hidden");
    newOption.removeAttribute("data-admin--fieldset--component-target");
    newOption.setAttribute("required", "required");
    // console.log('newOption1', newOption)
    newOption.innerHTML = newOption.innerHTML.replace(/REQUIREMENT_INDEX/g, newIndex);
    // console.log('newOption2', newOption)
    event.target.closest(".components-fieldset").querySelector(".component-admin-fieldset-option-list").appendChild(newOption);
    
    const switchBtn = newOption.querySelector('input.is-switch');
    if(switchBtn) { switchBtn.addEventListener("change", this.onFieldsetCorrectOptionChange.bind(this) ) }

    newOption.querySelectorAll('textarea').forEach((input) => {
      input.required = true
    })

    newOption.querySelectorAll('input, textarea, select').forEach((input) => {
      input.disabled = false
    })
  }

  removeOption(event) {
    event.preventDefault();
    const wrapper = event.target.closest(".components-fieldset-option").parentNode;
    if(wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }
  }

  fieldsetCorrectOptionChange(target){
    
    const correctInputs = target.closest('.component-admin-fieldset-option-list').querySelectorAll("input.is-switch")
    const creditsInputs = target.closest('.component-admin-fieldset-option-list').querySelectorAll(".fieldset-option-credits-container")
    // console.log(creditsInputs)
    if(!correctInputs){ return false; }

    if(target.checked){
      correctInputs.forEach((input) => {
        // console.log('input', input, input == target)
        if(input !== target){ 
          input.checked = false
        }
      })
      creditsInputs.forEach((container) => {
        container.classList.add("is-hidden")
        container.querySelector('input').value = null
      })
    } else {
      creditsInputs.forEach((container) => {
        container.classList.remove("is-hidden")
      })
    }
  }

  onFieldsetCorrectOptionChange(target){
    this.fieldsetCorrectOptionChange(event.currentTarget)
  }

  registerFieldsetCorrectOptionChange(){
    const correctInputs = this.element.querySelectorAll(".components-fieldset-option .is-switch")
    if(!correctInputs){ return false; }

    // this.frequencyChange(freqInput)
    correctInputs.forEach((input) => {
      input.addEventListener("change", this.onFieldsetCorrectOptionChange.bind(this) )
    })
  }
}