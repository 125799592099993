import { Controller } from "stimulus"
import MetaMaskOnboarding from '@metamask/onboarding'
// import { get } from '@rails/request.js'

export default class extends Controller {
  // static targets = ["wallet"]
  static values = {input: String}

  connect() {
    const inputId = this.inputValue
    const onboarding = new MetaMaskOnboarding();
    const onboardButton = document.getElementById('onboard');
    let accounts;

    const updateButton = () => {
      if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
        onboardButton.innerText = I18n.t('metamask.install');
        onboardButton.onclick = () => {
          onboardButton.innerText = I18n.t('metamask.onboarding');
          onboardButton.disabled = true;
          onboarding.startOnboarding();
        };
      } else if (accounts && accounts.length > 0) {
        onboardButton.innerText = I18n.t('metamask.connected');
        onboardButton.disabled = true;
        onboarding.stopOnboarding();
      } else {
        onboardButton.innerText = I18n.t('metamask.connect');
        onboardButton.onclick = async () => {
          getAccounts();
        };
      }
    };
    updateButton();

    async function getAccounts() {
      accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
      const account = accounts[0];
      // console.log(accounts)
      // const response = fetch("/wallet", {
      //   method: "POST",
      //   headers: {
      //     "Content-type": "application/json"
      //   },
      //   body: JSON.stringify({address: account})
      // })
      // let address_display = document.querySelector("p");
      // address_display.innerText = "Your wallet address : " + account;
      let input = document.getElementById(inputId);
      if(input){
        if(input.type == 'select-one'){
          input.value = account;
          let options = []
          for(let i=0; i<accounts.length; i++){
            options.push('<option>'+accounts[i]+'</option>')
          }
          input.innerHTML = options.join("")
        } else {
          input.value = account;
        }
      }
      
      updateButton();
    }
  }
}