
import { Controller } from "stimulus"
import consumer from "../../channels/consumer"
// import { Controller } from "bulma-extensions/calendar"

// import {bulmaCalendar} from 'bulma-extensions';

// require('bulma-extensions/bulma-calendar')

export default class extends Controller {
  static targets = [ "input" ]
  static values = { session: String }

  connect() {
    this.currentStep = 0;
    this.delay = 1200; //1200
    const context = this;
    // this.element.querySelectorAll("input").forEach(($el) => {
    //   $el.addEventListener("change", context.toggleButton(context));
    //   $el.addEventListener("click", context.toggleButton(context));
    // })
    document.addEventListener('change', function (event) {
      if (event.target.matches('input[type="radio"], input[type="checkbox"], input[type="text"], input[type="email"], input[type="tel"]')) {
        console.log('Radio or checkbox changed:', event.target);
        context.toggleButton(context);
      }
    });
    document.addEventListener('keyup', function (event) {
      if (event.target.matches('input[type="text"], input[type="email"], input[type="tel"]')) {
        console.log('Radio or checkbox changed:', event.target);
        context.toggleButton(context);
      }
    });

    console.log('sessionIdValue', this.sessionValue)

    consumer.subscriptions.create({channel: "OpenaiResponsesChannel", session_id: this.sessionValue}, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log('connected OpenaiResponsesChannel')
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        // Update your page with the data['response']

        const step_container = document.querySelector("#wizard-step-6")
        step_container.querySelector('.strategy-loading').classList.add('is-hidden')
        const strategy_field = step_container.querySelector('.strategy-field')
        strategy_field.querySelector("textarea").value = data['response']
        strategy_field.querySelector("textarea").scrollTop = strategy_field.querySelector("textarea").scrollHeight;
        strategy_field.classList.remove('is-hidden')
        const wizard_buttons = document.getElementById('wizard_buttons')
        if(wizard_buttons.querySelector('.cta-title')){
          wizard_buttons.querySelector('.cta-title').classList.remove('is-hidden') 
        }
        const next_button = wizard_buttons.querySelector('button')
        next_button.classList.remove('is-loading')
        next_button.removeAttribute('disabled')

        if(gtag != undefined){ 
          gtag('page_view', 'websummit/strategy');
          gtag('event', 'wizard_step_strategy', { 'event_category': 'wizard', 'event_label': 'strategy' });
        }

      }
    });
  }

  nextStep(event) {

    if(this.currentStep == 9){return};
    const button_wrap = event.currentTarget.closest('.button-wrap')
    if(button_wrap && button_wrap.querySelector('input[type=radio]')){
      
      event.currentTarget.closest('.wizard-types').querySelectorAll('input[type=radio]').forEach((el) => {
        el.checked = false;
      });
      event.currentTarget.closest('.button-wrap').querySelector('input[type=radio]').checked = true;
    }

    var $this = event.currentTarget;
    $this.classList.add("is-loading");
    const context = this;
    let currentStep = this.currentStep;
    setTimeout(function () {
      $this.classList.remove("is-loading");
      context.goToStep(currentStep + 1, context);
    }, this.delay);
  }

  previousStep(event) {
    console.log("previous step")

    if(this.currentStep == 0){return};

    var $this = event.currentTarget;
    $this.classList.add("is-loading");
    const context = this;
    let currentStep = this.currentStep;
    setTimeout(function () {
      $this.classList.remove("is-loading");
      context.goToStep(currentStep - 1, context);
    }, this.delay);
  }

  goToStep(step, context = this) {
    context.currentStep = step;

    if(gtag != undefined){ 
      gtag('page_view', `websummit/step${context.currentStep}`);
      gtag('event', `wizard_step_${context.currentStep}`, { 'event_category': 'wizard', 'event_label': context.currentStep });
    }

    console.log("wrapper", context.element.querySelector(".wizard-v1-wrapper .inner-wrapper"))
  
    context.element.querySelectorAll(".wizard-v1-wrapper .inner-wrapper").forEach(($el) => {
      $el.classList.remove('is-active');
    });
    context.element.querySelector("#wizard-step-" + step).classList.add("is-active");
  
    // var stepTitle = this.element.querySelector(".inner-wrapper.is-active").getAttribute("data-step-title");
    // var titleHtml = `
    //       <span class="title-wrap">Step ${
    //         step + 1
    //       }: <span>${stepTitle}</span></span>
    //   `;
      // this.element.querySelector(".is-wizard-title").innerHTML = titleHtml;
  
      // this.element.querySelector(".project-preview-loader").classList.add("is-active");
  
    // if (context.currentStep > 0) {
    //   context.element.querySelector(".wizard-buttons").classList.add("is-active");
    // }
  
    if (context.currentStep == 0) {
      context.handleProgress(0, context);
      context.element.querySelector(".wizard-buttons").classList.add("is-active");
    } else if (context.currentStep == 1) {
      context.handleProgress(16, context);
      context.element.querySelector(".wizard-buttons").classList.remove("is-active");
    } else if (context.currentStep == 2) {
      context.handleProgress(32, context);
      context.element.querySelector(".wizard-buttons").classList.remove("is-active");
    } else if (context.currentStep == 3) {
      context.handleProgress(48, context);
      context.element.querySelector(".wizard-buttons").classList.add("is-active");
    } else if (context.currentStep == 4) {
      context.handleProgress(64, context);
      context.element.querySelector(".wizard-buttons").classList.add("is-active");
      // const btn = context.element.querySelector(".wizard-buttons .wizard-button-next")
      // btn.innerHTML = "Submit";
      // btn.setAttribute('type', 'submit');
    } else if (context.currentStep == 5) {
      context.handleProgress(80, context);
      context.element.querySelector(".wizard-buttons").classList.add("is-active");
      context.element.querySelector(".wizard-buttons #cta-text").classList.remove("is-hidden");
      const btn = context.element.querySelector(".wizard-buttons .wizard-button-next")
      btn.innerHTML = "Generate";
      btn.setAttribute('type', 'submit');
    } else if (context.currentStep == 6) {
      context.handleProgress(100, context);
      this.loadingStrategy(context);
      // context.element.querySelector(".wizard-buttons").classList.add("is-active");
    } else if (context.currentStep == 7) {
      context.handleProgress(100, context);
      context.element.querySelector(".wizard-buttons .cta-title").classList.add("is-hidden");
      context.element.querySelector(".wizard-buttons .wizard-button-next").innerHTML = "Next";
      context.element.querySelector(".wizard-buttons").classList.add("is-active");
    } else if (context.currentStep == 8) {
      context.handleProgress(100, context);
      context.element.querySelector(".wizard-buttons").classList.add("is-active");
    } else if (context.currentStep == 9) {
      context.handleProgress(100, context);
      context.element.querySelector(".wizard-buttons").classList.add("is-active");
      const btn = context.element.querySelector(".wizard-buttons .wizard-button-next")
      const form = context.element
      console.log("btn", btn)

      btn.innerHTML = "Ready to go!";
      btn.setAttribute('type', 'submit');
      form.setAttribute('action', '/websummit/save');
    }

    context.toggleButton(context);
  
  }

  toggleButton(context) {
    console.log("toggle button")
    const textInput = context.element.querySelector("#wizard-step-" + context.currentStep).querySelector("input.input")
    const checkInputs = context.element.querySelector("#wizard-step-" + context.currentStep).querySelectorAll("input:checked")
    const inputs = context.element.querySelector("#wizard-step-" + context.currentStep).querySelectorAll("input")
    if(inputs.length == 0 || (checkInputs.length > 0) || (textInput && textInput.value.trim() !== '')){
      context.element.querySelectorAll(".wizard-buttons button").forEach(($el) => {
        $el.removeAttribute("disabled");
      });
    } else {
      context.element.querySelectorAll(".wizard-buttons button").forEach(($el) => {
        $el.setAttribute("disabled", "disabled");
      });
    }
    
  }

  handleProgress(value, context = this) {
    context.element.querySelector("#wizard-progress").value = value;
  }

  loadingStrategy(context) {
    const countdown = context.element.querySelector('.strategy-loading .strategy-countdown')
    var totalTime = 0;

    var timer = setInterval(function() {

      totalTime += 10;
      switch(totalTime) {
        case 10:
          countdown.innerHTML = I18n.t('websummit.step_6.strategy.loading.step_1');
          break;
        case 20:
          countdown.innerHTML = I18n.t('websummit.step_6.strategy.loading.step_2');
          break;
        case 30:
          countdown.innerHTML = I18n.t('websummit.step_6.strategy.loading.step_3');
          break;
        case 40:
          countdown.innerHTML = I18n.t('websummit.step_6.strategy.loading.step_4');
          break;
        case 50:
          countdown.innerHTML = I18n.t('websummit.step_6.strategy.loading.step_5');
          break;
        case 60:
          countdown.innerHTML = I18n.t('websummit.step_6.strategy.loading.step_6');
          break;
      }
      // If the count down is finished, write some text
      if (totalTime > 50) {
        clearInterval(timer);
      }
    }, 10000);
  } 
}