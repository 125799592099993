window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

document.addEventListener('turbo:load', function(event){
  if(typeof(gtag) == "function") {
    gtag('config', 'G-9NHS9FWTVR');
    gtag('config', 'AW-370513348');
    if(document.querySelector("#community_googleanalytics")){
      gtag('config', document.querySelector("#community_googleanalytics").value)
    }
    gtag("config", 'UA-131466699-3', {
      "page_title": document.title,
      "page_path": location.href.replace(location.origin, ""),
    })
  }
});

export default gtag