import ApplicationController from '../application_controller'
// var card = require("card");

export default class extends ApplicationController {
  static targets = [  ]
  // static values = { initialCountry: String }

  connect () {
    const config = {class: "notification is-light", message: this.element.dataset.message}

    if(this.element.dataset.title) {config["title"] = this.element.dataset.title}

    switch(this.element.dataset.type){
      case 'error':
        iziToast.error(config);
        break;
      case 'success':
        iziToast.success(config);
        break;
    }
      
  }

  disconnect(){
  }
}
