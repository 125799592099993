import ApplicationController from '../application_controller'
import { post } from '@rails/request.js'
import { patch } from '@rails/request.js';

/* This is the custom StimulusReflex controller for the Supporter Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  // static targets = []
  // static values = { id: Integer }

  connect () {
    super.connect()
  }


  async submit(event) {
    event.preventDefault();
    // event.stopPropagation();
    
    var controller = this;
    var stimulusTarget = event.currentTarget;
    document.querySelector("#flash_notifications").innerHTML = ""
    // event.currentTarget.closest("form").submit();
    // controller.stimulate("Admin::Users#update", controller.element);

    const form = this.element;
    const action_url = form.action;

    const formData = new FormData(form);
    formData.append('_method', 'PATCH');
    // const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const responseForm = await patch(action_url, {
      responseKind: 'turbo-stream', 
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        // 'X-CSRF-Token': csrfToken
      },
      body: formData
    });

    console.log(responseForm)

  }

}
