// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="site">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { Application } from '@splinetool/runtime';

export default class extends Controller {
  static targets = [ "inputCoinName", "typewriter", "submitBtn" ]

  connect() {
    
    this.currentSlide = 1;
    const canvas = document.getElementById('canvas3d');
    const spline = new Application(canvas);
    const myVariables = { viewportWidth: 800, viewportHeight: 800 };
    spline.load('https://prod.spline.design/WTUoqVAnlEH0EjmW/scene.splinecode', myVariables).then(() => {
      // const obj = spline.findObjectByName('Cube');
      // or
      // const obj = spline.findObjectById('7AF5EBC0-09BB-4720-B045-F478F8053AA4');
      spline.setSize({width: 800, height: 800});
      
      // console.log(spline, spline.data, spline.canvas); // Spline Object => { name: 'Cube', id: '7AF5EBC0-09BB-4720-B045-F478F8053AA4', position: {}, ... }
  
      // move the object in 3D space
      // obj.position.x += 10;
      canvas.style.width = (canvas.parentElement.offsetWidth - 50)+'px';
      canvas.style.height = (canvas.parentElement.offsetWidth)+'px';
    });

    this.startSlideRoutine();

  }

  startSlideRoutine() {
    if (this.slideRoutine) {
      clearTimeout(this.slideRoutine);
    }
    if (this.slidesInterval) {
      clearTimeout(this.slidesInterval);
    }
    

    function frame() {
      if (width >= 100) {
        clearInterval(this.slidesInterval);
      } else {
        width +=1;
        // console.log('width', document.getElementById('slide-indicator').style.width);
        document.getElementById('slide-indicator').style.width = width + '%';
      }
    }

    let width = 0;
    this.slidesInterval = setInterval(frame, 150); // adjust this value to change the speed of the progress bar

    

    this.slideRoutine = setInterval(() => {
      this.nextSlide();
      // var left_indicator = (parseInt(slide.dataset.slide) - 1) * 25;
      // document.getElementById('slide-indicator').classList.remove('left-*');
      // document.getElementById('slide-indicator').style.left = left_indicator+'%';

      // console.log('next slide', this.currentSlide);
    }, 15000);
  }

  onChangeSlide(event) {
    event.preventDefault();

    const target = event.target;
    this.currentSlide = parseInt(target.dataset.slide);

    this.changeSlide(target);
  }

  onPrevSlide(event) {
    event.preventDefault();
    this.currentSlide -= 1;
    if (this.currentSlide < 1) {
      this.currentSlide = 4;
    }
    const slide = document.getElementById('slide-item-'+this.currentSlide);
    this.changeSlide(slide);
  }

  onNextSlide(event) {
    event.preventDefault();
    this.nextSlide();
  }

  nextSlide() {
    this.currentSlide += 1;
    if (this.currentSlide > 4) {
      this.currentSlide = 1;
    }
    const slide = document.getElementById('slide-item-'+this.currentSlide);
    this.changeSlide(slide);
  }

  changeSlide(slide) {
    this.startSlideRoutine();

    slide.closest('#slides').querySelectorAll(".slide-item-active")?.forEach((el) => {
      el.classList.remove('slide-item-active');
    });
    slide.classList.add('slide-item-active');
    
    document.getElementById('slides-container').querySelectorAll(".slide-content")?.forEach((el) => {
      el.classList.add('hidden');
    })
    document.getElementById('slide-content-'+this.currentSlide).classList.remove('hidden');
    
  }

  onChangeFaq(event) {
    event.preventDefault();

    const target = event.target;

    this.changeFaq(target);
  }

  changeFaq(faq) {

    

    if (faq.classList.contains('faq-item')) {
    
      faq.closest('#faq-list').querySelectorAll(".faq-item-active")?.forEach((el) => {
        el.classList.remove('faq-item-active');
      });
      faq.classList.add('faq-item-active');

      faq.closest('#faq').querySelectorAll(".faq-text-item")?.forEach((el) => {
        el.classList.add('hidden');
      });
  
      faq.closest('#faq').querySelector("#faq-text-"+faq.dataset.index).classList.remove('hidden');

    } else {
      faq.closest('#faq-combo-list').querySelectorAll(".faq-combo-list-icon-check")?.forEach((el) => {
        el.style.display = 'none';
      });
      faq.closest('.faq-combo-list-item').querySelector(".faq-combo-list-icon-check").style.display = 'block';

      faq.closest('#faq-combo').querySelector("#faq-combo-selected-label").innerText = faq.closest('.faq-combo-list-item').querySelector(".faq-combo-list-item-label").innerText;

      faq.closest('#faq').querySelectorAll(".faq-text-item")?.forEach((el) => {
        el.classList.add('hidden');
      });
  
      // console.log(faq.closest('.faq-combo-list-item'));
      faq.closest('#faq').querySelector("#faq-text-"+faq.closest(".faq-combo-list-item").dataset.index).classList.remove('hidden');

      this.hideComboList();
    }
    
  }

  onOpenFaqCombo(event) {
    event.preventDefault();
    const target = event.target;
    // console.log(target);
    // target.closest('#faq-combo').querySelector("#faq-combo-list").style.display = 'block';
    this.showComboList();
  }

  showComboList() {
    // event.preventDefault();

    let element = document.getElementById('faq-combo-list');
    // console.log(element);
    element.classList.remove('translate-y-4', 'opacity-0');
    element.classList.add('translate-y-0', 'opacity-100');
    setTimeout(() => {
      element.classList.remove('hidden');
    }, 400);
  }

  hideComboList() {
    // event.preventDefault();

    let element = document.getElementById('faq-combo-list');
    element.classList.remove('translate-y-0', 'opacity-100');
    element.classList.add('translate-y-4', 'opacity-0');
    setTimeout(() => {
      element.classList.add('hidden');
    }, 400);
  }

  showMobileMenu(event) {
    event.preventDefault();
    document.getElementById('mobile-menu').style.display = 'flex';
    document.getElementById('mobile-menu').style.transform = 'translateY(0)';
  }

  hideMobileMenu(event) {
    event.preventDefault();
    document.getElementById('mobile-menu').style.display = 'none';
    document.getElementById('mobile-menu').style.transform = 'translateY(-100%)';
  }

  
}

