import ApplicationController from '../application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['activeForm', 'rarityLabel', 'quantityInput']
  
  connect(){
    super.connect()
    const attr_value = this.element.querySelector("input[name='community_cashout[method]']:checked").value
    this.toggleDisplay(attr_value)
  }
  

  toggleMethod(event){
    const attr_name = event.target.getAttribute('name')
    const attr_value = this.element.querySelector("input[name='"+attr_name+"']:checked").value
    this.toggleDisplay(attr_value)
  }

  toggleDisplay(attr_value){
    if(attr_value == 'pix'){
      this.element.querySelector('div.paypal_account_container').classList.add('is-hidden');
      this.element.querySelector('div.pix_account_container').classList.remove('is-hidden');
    } else {
      this.element.querySelector('div.paypal_account_container').classList.remove('is-hidden');
      this.element.querySelector('div.pix_account_container').classList.add('is-hidden');
    }
  }
  
}