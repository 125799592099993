import ApplicationController from './application_controller'
// import Card from 'card'
// var card = require("card");

export default class extends ApplicationController {

  static values = { redirectUrl: String }

  connect () {
    super.connect()
    // add your code here, if applicable
    window.waitingSubmit = false
  }

  chooseOption(event){
    event.preventDefault();
    // event.stopPropagation();
    
    let target = event.target.closest(".payment-option-container")
    let container = target.dataset.container

    this.element.querySelectorAll(".payment-container").forEach(element => {
      // element.classList.remove('is-active')
      element.classList.add('is-hidden')
    });
    this.element.querySelectorAll(".payment-option-container").forEach(element => {
      element.classList.remove('is-active')
    });
    target.classList.add('is-active')
    this.element.querySelector(container).classList.remove('is-hidden')
  }

  submit(e){
    // e.preventDefault();
    // var pageLoader = document.querySelector(".pageloader");
    // pageLoader.classList.add("is-active");
    // window.setTimeout(() => {
    //   Turbolinks.visit(this.redirectUrlValue)
    // }, 2000);

  }
}
