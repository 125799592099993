import ApplicationController from './application_controller'
// import Bulma from '@vizuaalog/bulmajs';
import Dropdown from '@vizuaalog/bulmajs/src/plugins/dropdown';


export default class extends ApplicationController {
  // static targets = ['carousel']
  // static values = { style: String }
  
  connect(){
    this.dropdown = new Dropdown({
      element: this.element
    });
  }

  disconnect(){
    this.element.replaceWith(this.element.cloneNode(true));
  }

  submitForm(e){
    e.preventDefault();
    const elem = e.target.closest("a")

    const action = elem.getAttribute("href")
    var form = document.querySelector(elem.dataset["form"])
    const form_action = form.getAttribute("action")
    form.setAttribute("action", action)
    form.submit()
    form.setAttribute("action", form_action)
    
  }

}