import ApplicationController from './application_controller'
import SmoothScroll from 'smooth-scroll';
import Rails from '@rails/ujs';
import { post, get } from '@rails/request.js'
const Uppy = require('@uppy/core')
const UppyEN = require('@uppy/locales/lib/en_US')
const UppyPT = require('@uppy/locales/lib/pt_BR')
const Dashboard = require('@uppy/dashboard')
const Webcam = require('@uppy/webcam')
// const ActiveStorageUpload = require('uppy-activestorage-upload')
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'
const ImageEditor = require('@uppy/image-editor')

// import I18n from 'i18n-js';


/* This is the custom StimulusReflex controller for the Supporter Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = ["formContainer", "replyContainer", 'trix_holder', 'show', 'edit', 'inputImage', 'imageContainer', 'inputVideo', 'videoContainer']
  // static values = { id: Integer }

  connect () {
    super.connect()
    // add your code here, if applicable
    if(this.hasInputImageTarget && this.hasImageContainerTarget){
      
      this.setupUppyImage(this.inputImageTarget, this.imageContainerTarget)
    }
    if(this.hasInputVideoTarget && this.hasVideoContainerTarget){
      this.setupUppyVideo(this.inputVideoTarget, this.videoContainerTarget)
    }
  }


  submit(event) {
    event.preventDefault();
    // event.stopPropagation();

    var controller = this;
    var stimulusTarget = event.currentTarget;

    if(controller.element.dataset['entryId'] == undefined)
      controller.stimulate("Entry#create", stimulusTarget).then(() => {
        if(this.hasImageContainerTarget) { 
          this.imageContainerTarget.classList.add('is-hidden') 
          this.imageContainerTarget.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))
        }
        if(this.hasVideoContainerTarget) { this.videoContainerTarget.classList.add('is-hidden') }
      });
    else{
      controller.stimulate("Entry#update", stimulusTarget);
    }

  }

  pin(event) {
    event.preventDefault();
    // event.stopPropagation();

    var controller = this;
    var stimulusTarget = event.currentTarget;

    let pageLoader = document.querySelector('.pageloader')
    pageLoader.classList.add("is-active");

    controller.stimulate("Entry#pin", stimulusTarget).then(payload => {
      pageLoader.classList.remove("is-active");
      iziToast.success({
        title: I18n.t("community.site.activities.pin.success.title"),
        message: I18n.t("community.site.activities.pin.success.body"),
        class: "notification is-light"
      });
      var scroll = new SmoothScroll();
      scroll.animateScroll(document.querySelector("#feed_posts_list"), null, {speed: 2000, header: '.webapp-navbar'});
    })
  }

  unpin(event) {
    event.preventDefault();
    // event.stopPropagation();

    var controller = this;
    var stimulusTarget = event.currentTarget;

    let pageLoader = document.querySelector('.pageloader')
    pageLoader.classList.add("is-active");

    controller.stimulate("Entry#unpin", stimulusTarget).then(payload => {
      pageLoader.classList.remove("is-active");
      iziToast.success({
        title: I18n.t("community.site.activities.unpin.success.title"),
        message: I18n.t("community.site.activities.unpin.success.body"),
        class: "notification is-light"
      });
      var scroll = new SmoothScroll();
      scroll.animateScroll(document.querySelector("#feed_posts_list"), null, {speed: 2000, header: '.webapp-navbar'});
    })
  }

  filter(event) {
    event.preventDefault();

    var stimulusTarget = event.currentTarget;
    var controller = this;

    var placeload = document.querySelector('#entries_placeload')
    placeload.classList.remove("is-hidden");

    document.querySelector("#feed_entries").innerHTML = ''

    var tabs = controller.element.querySelectorAll("li")
      
    tabs.forEach(element => {
      if(element.dataset.category == stimulusTarget.dataset.category)
        element.classList.add("is-active")
      else
        element.classList.remove("is-active")
    })

    controller.stimulate("Entry#filter", stimulusTarget).then(payload => {
      placeload.classList.add("is-hidden")
    })
  }

  delete(event){

    var controller = this;
    var stimulusTarget = event.currentTarget;

    Bulma.create('alert', {
      type: 'danger',
      title: I18n.t("community.site.activities.destroy.confirm.title"),
      body: I18n.t("community.site.activities.destroy.confirm.body"),
      confirm: {
        label: I18n.t("community.site.activities.destroy.confirm.btn.confirm"),
        onClick: function(){
          controller.stimulate("Entry#delete", stimulusTarget);
        }
      },
      cancel: I18n.t("community.site.activities.destroy.confirm.btn.cancel")
    });
  }

  editToggle(event){
    this.showTarget.classList.toggle("is-hidden");
    this.editTarget.classList.toggle("is-hidden");
    fetch(event.currentTarget.dataset['url'])
      .then(response => response.text())
      .then(html => {this.editTarget.innerHTML = html} )
      .catch(error => { console.log(error)})
  }

  cancelEdit(event){
    document.getElementById("edit_community_entry_"+event.currentTarget.dataset['id']).classList.toggle("is-hidden");
    document.getElementById("show_community_entry_"+event.currentTarget.dataset['id']).classList.toggle("is-hidden");
  }

  showComment() {
    this.replyContainerTarget.classList.add("is-hidden")
    this.formContainerTarget.classList.remove("is-hidden")
    var scroll = new SmoothScroll();
    this.formContainerTarget.querySelector("textarea").focus();
    scroll.animateScroll(this.formContainerTarget, null, {speed: 2000, header: '.webapp-navbar'});
  }

  cancelComment(event = null) {
    if(event == null || event.target.dataset.id == ""){
      this.replyContainerTarget.classList.remove("is-hidden")
      this.formContainerTarget.classList.add("is-hidden")
    } else {
      this.editComment(event)
    }
  }

  editComment(event) {

    var showComment = this.element.querySelector("#container_show_comment_"+event.target.dataset.id)
    if (showComment) showComment.classList.toggle("is-hidden")

    var editComment = this.element.querySelector("#container_edit_comment_"+event.target.dataset.id)
    if (editComment) editComment.classList.toggle("is-hidden")
  }

  setupUppyImage(element, container) {
    let trigger = this.inputImageTarget
    let form = element.closest('form')
    let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
    let field_name = container.dataset.uppy

    trigger.addEventListener("click", (event) => { event.preventDefault(); event.stopPropagation(); } )

    let uppy = new Uppy({
      // autoProceed: true,
      allowMultipleUploads: false,
      logger: Uppy.debugLogger,
      locale: I18n.locale == 'pt' ? UppyPT : UppyEN,
      restrictions: {
        maxFileSize: 10*1024*1024,
        minFileSize: null,
        maxTotalFileSize: null,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*']
      }
    })

    uppy.use(Dashboard, {
      trigger: trigger,
      closeAfterFinish: true,
      autoOpenFileEditor: true,
    })

    uppy.use(ImageEditor, {
      target: Dashboard,
      quality: 0.8,
      id: 'pteImageEditor',
      cropperOptions: {
        viewMode: 1,
        background: false,
        autoCropArea: 1,
        responsive: true,
        initialAspectRatio: 2,
        // aspectRatio: 2,
        autoCrop: true,
        // minCropBoxWidth: 400,
        // minCropBoxHeight: 200,
      },
      actions: {
        revert: true,
        rotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      }
    })

    uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url
    })

    uppy.on('complete', (result) => {
      // Rails.ajax
      // or show a preview:
      container.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

      result.successful.forEach(file => {
        this.appendImageUploadedFile(container, file, field_name)
        this.setImagePreview(element, file, container)
      })

      uppy.reset()
    })
  }

  appendImageUploadedFile(container, file, field_name) {
    const hiddenField = document.createElement('input')

    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', field_name)
    hiddenField.setAttribute('data-pending-upload', true)
    hiddenField.setAttribute('value', file.response.signed_id)

    container.appendChild(hiddenField)
  }

  setImagePreview(element, file, container) {
    let preview = container.querySelector('[data-behavior="uppy-preview"]')
    if (preview) {
      let src = (file.preview) ? file.preview : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
      preview.src = src
      
      container.classList.remove("is-hidden")
    }
  }

  removeImage(){
    let container = this.imageContainerTarget
    container.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))
    container.querySelectorAll('[data-behavior="uppy-preview"]').forEach(element => element.src = '')
    container.classList.add("is-hidden")
  }

  setupUppyVideo(element, container) {
    let trigger = element
    let form = element.closest('form')
    let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
    let field_name = container.dataset.uppy

    trigger.addEventListener("click", (event) => { event.preventDefault(); event.stopPropagation(); } )

    let uppy = new Uppy({
      // autoProceed: true,
      allowMultipleUploads: false,
      logger: Uppy.debugLogger,
      locale: I18n.locale == 'pt' ? UppyPT : UppyEN,
      restrictions: {
        maxFileSize: 40*1024*1024,
        minFileSize: null,
        maxTotalFileSize: null,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['video/*']
      }
    })

    uppy.use(Dashboard, {
      trigger: trigger,
      closeAfterFinish: true,
      // autoOpenFileEditor: true,
    })

    if (typeof MediaRecorder !== 'undefined') {
      uppy.use(Webcam, { 
        target: Dashboard,
        onBeforeSnapshot: () => Promise.resolve(),
        countdown: true,
        modes: [
          'video-audio',
        ],
        mirror: true,
        videoConstraints: {
          facingMode: 'user',
          width: { min: 720, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 800, max: 1080 },
        },
        showRecordingLength: true,
        preferredVideoMimeType: 'video/mp4'
      })
    }

    uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url
    })

    uppy.on('complete', (result) => {
      // Rails.ajax
      // or show a preview:
      container.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

      result.successful.forEach(file => {
        this.appendVideoUploadedFile(container, file, field_name)
        this.setVideoPreview(element, file, container)
      })

      uppy.reset()
    })
  }

  appendVideoUploadedFile(container, file, field_name) {
    const hiddenField = document.createElement('input')

    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', field_name)
    hiddenField.setAttribute('data-pending-upload', true)
    hiddenField.setAttribute('value', file.response.signed_id)

    container.appendChild(hiddenField)
  }

  setVideoPreview(element, file, container) {
    let preview = container.querySelector('[data-behavior="uppy-preview"]')
    if (preview) {
      const key_param = new URLSearchParams({'key': file.response.key}).toString()
      Rails.ajax({
        type: "get",
        url: preview.dataset.previewUrl,
        data: key_param,
        success: function(data) {
          preview.src = data.media_url
          container.classList.remove("is-hidden")
        },
      })
    }
  }

  removeVideo(){
    let container = this.videoContainerTarget
    container.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))
    container.querySelectorAll('[data-behavior="uppy-preview"]').forEach(element => element.src = '')
    container.classList.add("is-hidden")
  }

  async pull_youtube_videos(event){
    try {
      // console.log(this.element.dataset.url)
      let pageLoader = document.querySelector('.pageloader')
      pageLoader.classList.add("is-active");

      const response = await post(this.element.dataset.url)
      // const body_json = await response.json
      const body_json = await response.json
      if(typeof body_json.redirect != 'undefined')
        Turbo.visit(body_json.redirect)
      else
        Turbo.visit(window.location.href)
      // return token;
    } catch (error) {
      console.error(error);
    }
  }

  async pull_twitter_status(event){
    try {
      // console.log(this.element.dataset.url)
      let pageLoader = document.querySelector('.pageloader')
      pageLoader.classList.add("is-active");

      const response = await post(this.element.dataset.url)
      // const body_json = await response.json
      const body_json = await response.json
      if(typeof body_json.redirect != 'undefined')
        Turbo.visit(body_json.redirect)
      else
        Turbo.visit(window.location.href)
      // return token;
    } catch (error) {
      console.error(error);
    }
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="supporter" to your markup alongside
   * data-reflex="Supporter#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Supporter#dance" data-controller="supporter">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Supporter#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "Supporter#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  // danceSuccess(element, reflex, noop, reflexId) {
  //   element.innerText = '\nDanced like no one was watching! Was someone watching?'
  // }

  // danceError(element, reflex, error, reflexId) {
  //   console.error('danceError', error);
  //   element.innerText = "\nCouldn\'t dance!"
  // }


  beforeReflex() {
    // this.trixHtml = this.trix_holderTarget.innerHTML
  }

  afterReflex() {
    if (this.element.tagName == 'FORM') { 
      this.element.reset(); 
      Rails.enableElement(this.element.querySelector("[type=submit]")); 
    }
  }

  likePostAction(event){
    // if(this.element.dataset.category == 'Twitter'){
    //   const response = await post(event.targe.element.dataset.url)
    // }

    get(event.target.closest('button').dataset.twitterUrl)
  }

  // finalizeDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nNow, the cleanup can begin!'
  // }
}
