import ApplicationController from '../application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['frequency', 'streakContainer']
  
  connect(){
    // super.connect()
    console.log('Streak Controller Connected')
    console.log(this.frequencyTarget, this.streakContainerTarget);

    if(this.frequencyTarget.value == 'daily'){
      this.enableStreak()
    } else {
      this.disableStreak()
    }
  }

  enableStreak(){
    this.streakContainerTarget.classList.remove('is-hidden')
  }

  disableStreak(){
    this.streakContainerTarget.classList.add('is-hidden')
    this.streakContainerTarget.value = null
  }

  onFrequencyChange(event){
    console.log("onFrequencyChange", event.target.value)
    if(event.target.value == 'daily'){
      this.enableStreak()
    } else {
      this.disableStreak()
    }
  }
  
}